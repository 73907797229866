@charset "UTF-8";
/*
Template Name: Shreyu - Responsive 5 Admin Dashboard
Author: CoderThemes
Version: 2.0.0
Email: support@coderthemes.com
File: Icons Css File
*/
@font-face {
  font-family: 'unicons';
  src: url("../fonts/unicons.eot?2256574");
  src: url("../fonts/unicons.eot?2256574#iefix") format("embedded-opentype"), url("../fonts/unicons.woff2?2256574") format("woff2"), url("../fonts/unicons.woff?2256574") format("woff"), url("../fonts/unicons.ttf?2256574") format("truetype"), url("../fonts/unicons.svg?2256574#unicons") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'unicons';
    src: url('../fonts/unicons.svg?2256574#unicons') format('svg');
  }
}
*/
.uil {
  line-height: inherit; }

[class^="uil-"]:before, [class*=" uil-"]:before {
  font-family: "unicons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  vertical-align: middle;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  font-size: 120%;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.uil-0-plus:before {
  content: '\e800'; }

/* '' */
.uil-10-plus:before {
  content: '\e801'; }

/* '' */
.uil-12-plus:before {
  content: '\e802'; }

/* '' */
.uil-13-plus:before {
  content: '\e803'; }

/* '' */
.uil-16-plus:before {
  content: '\e804'; }

/* '' */
.uil-17-plus:before {
  content: '\e805'; }

/* '' */
.uil-18-plus:before {
  content: '\e806'; }

/* '' */
.uil-21-plus:before {
  content: '\e807'; }

/* '' */
.uil-3-plus:before {
  content: '\e808'; }

/* '' */
.uil-500px:before {
  content: '\e809'; }

/* '' */
.uil-6-plus:before {
  content: '\e80a'; }

/* '' */
.uil-abacus:before {
  content: '\e80b'; }

/* '' */
.uil-accessible-icon-alt:before {
  content: '\e80c'; }

/* '' */
.uil-adjust-alt:before {
  content: '\e80d'; }

/* '' */
.uil-adjust-circle:before {
  content: '\e80e'; }

/* '' */
.uil-adjust-half:before {
  content: '\e80f'; }

/* '' */
.uil-adjust:before {
  content: '\e810'; }

/* '' */
.uil-adobe-alt:before {
  content: '\e811'; }

/* '' */
.uil-adobe:before {
  content: '\e812'; }

/* '' */
.uil-airplay:before {
  content: '\e813'; }

/* '' */
.uil-align-alt:before {
  content: '\e814'; }

/* '' */
.uil-align-center-alt:before {
  content: '\e815'; }

/* '' */
.uil-align-center-h:before {
  content: '\e816'; }

/* '' */
.uil-align-center-justify:before {
  content: '\e817'; }

/* '' */
.uil-align-center-v:before {
  content: '\e818'; }

/* '' */
.uil-align-center:before {
  content: '\e819'; }

/* '' */
.uil-align-justify:before {
  content: '\e81a'; }

/* '' */
.uil-align-left-justify:before {
  content: '\e81b'; }

/* '' */
.uil-align-left:before {
  content: '\e81c'; }

/* '' */
.uil-align-letter-right:before {
  content: '\e81d'; }

/* '' */
.uil-align-right-justify:before {
  content: '\e81e'; }

/* '' */
.uil-align-right:before {
  content: '\e81f'; }

/* '' */
.uil-align:before {
  content: '\e820'; }

/* '' */
.uil-amazon:before {
  content: '\e821'; }

/* '' */
.uil-ambulance:before {
  content: '\e822'; }

/* '' */
.uil-analysis:before {
  content: '\e823'; }

/* '' */
.uil-analytics:before {
  content: '\e824'; }

/* '' */
.uil-anchor:before {
  content: '\e825'; }

/* '' */
.uil-android-alt:before {
  content: '\e826'; }

/* '' */
.uil-android-phone-slash:before {
  content: '\e827'; }

/* '' */
.uil-android:before {
  content: '\e828'; }

/* '' */
.uil-angle-double-down:before {
  content: '\e829'; }

/* '' */
.uil-angle-double-left:before {
  content: '\e82a'; }

/* '' */
.uil-angle-double-right:before {
  content: '\e82b'; }

/* '' */
.uil-angle-double-up:before {
  content: '\e82c'; }

/* '' */
.uil-angle-down:before {
  content: '\e82d'; }

/* '' */
.uil-angle-left:before {
  content: '\e82e'; }

/* '' */
.uil-angle-right-b:before {
  content: '\e82f'; }

/* '' */
.uil-angle-right:before {
  content: '\e830'; }

/* '' */
.uil-angle-up:before {
  content: '\e831'; }

/* '' */
.uil-angry:before {
  content: '\e832'; }

/* '' */
.uil-ankh:before {
  content: '\e833'; }

/* '' */
.uil-annoyed-alt:before {
  content: '\e834'; }

/* '' */
.uil-annoyed:before {
  content: '\e835'; }

/* '' */
.uil-apple-alt:before {
  content: '\e836'; }

/* '' */
.uil-apple:before {
  content: '\e837'; }

/* '' */
.uil-apps:before {
  content: '\e838'; }

/* '' */
.uil-archive-alt:before {
  content: '\e839'; }

/* '' */
.uil-archive:before {
  content: '\e83a'; }

/* '' */
.uil-archway:before {
  content: '\e83b'; }

/* '' */
.uil-arrow-break:before {
  content: '\e83c'; }

/* '' */
.uil-arrow-circle-down:before {
  content: '\e83d'; }

/* '' */
.uil-arrow-circle-left:before {
  content: '\e83e'; }

/* '' */
.uil-arrow-circle-right:before {
  content: '\e83f'; }

/* '' */
.uil-arrow-circle-up:before {
  content: '\e840'; }

/* '' */
.uil-arrow-compress-h:before {
  content: '\e841'; }

/* '' */
.uil-arrow-down-left:before {
  content: '\e842'; }

/* '' */
.uil-arrow-down-right:before {
  content: '\e843'; }

/* '' */
.uil-arrow-down:before {
  content: '\e844'; }

/* '' */
.uil-arrow-from-right:before {
  content: '\e845'; }

/* '' */
.uil-arrow-from-top:before {
  content: '\e846'; }

/* '' */
.uil-arrow-growth:before {
  content: '\e847'; }

/* '' */
.uil-arrow-left:before {
  content: '\e848'; }

/* '' */
.uil-arrow-random:before {
  content: '\e849'; }

/* '' */
.uil-arrow-resize-diagonal:before {
  content: '\e84a'; }

/* '' */
.uil-arrow-right:before {
  content: '\e84b'; }

/* '' */
.uil-arrow-to-bottom:before {
  content: '\e84c'; }

/* '' */
.uil-arrow-to-right:before {
  content: '\e84d'; }

/* '' */
.uil-arrow-up-left:before {
  content: '\e84e'; }

/* '' */
.uil-arrow-up-right:before {
  content: '\e84f'; }

/* '' */
.uil-arrow-up:before {
  content: '\e850'; }

/* '' */
.uil-arrows-h-alt:before {
  content: '\e851'; }

/* '' */
.uil-arrows-h:before {
  content: '\e852'; }

/* '' */
.uil-arrows-left-down:before {
  content: '\e853'; }

/* '' */
.uil-arrows-maximize:before {
  content: '\e854'; }

/* '' */
.uil-arrows-merge:before {
  content: '\e855'; }

/* '' */
.uil-arrows-resize-h:before {
  content: '\e856'; }

/* '' */
.uil-arrows-resize-v:before {
  content: '\e857'; }

/* '' */
.uil-arrows-resize:before {
  content: '\e858'; }

/* '' */
.uil-arrows-right-down:before {
  content: '\e859'; }

/* '' */
.uil-arrows-shrink-h:before {
  content: '\e85a'; }

/* '' */
.uil-arrows-shrink-v:before {
  content: '\e85b'; }

/* '' */
.uil-arrows-up-right:before {
  content: '\e85c'; }

/* '' */
.uil-arrows-v-alt:before {
  content: '\e85d'; }

/* '' */
.uil-arrows-v:before {
  content: '\e85e'; }

/* '' */
.uil-assistive-listening-systems:before {
  content: '\e85f'; }

/* '' */
.uil-asterisk:before {
  content: '\e860'; }

/* '' */
.uil-at:before {
  content: '\e861'; }

/* '' */
.uil-atm-card:before {
  content: '\e862'; }

/* '' */
.uil-atom:before {
  content: '\e863'; }

/* '' */
.uil-auto-flash:before {
  content: '\e864'; }

/* '' */
.uil-award-alt:before {
  content: '\e865'; }

/* '' */
.uil-award:before {
  content: '\e866'; }

/* '' */
.uil-baby-carriage:before {
  content: '\e867'; }

/* '' */
.uil-backpack:before {
  content: '\e868'; }

/* '' */
.uil-backspace:before {
  content: '\e869'; }

/* '' */
.uil-backward:before {
  content: '\e86a'; }

/* '' */
.uil-bag-alt:before {
  content: '\e86b'; }

/* '' */
.uil-bag-slash:before {
  content: '\e86c'; }

/* '' */
.uil-bag:before {
  content: '\e86d'; }

/* '' */
.uil-balance-scale:before {
  content: '\e86e'; }

/* '' */
.uil-ball:before {
  content: '\e86f'; }

/* '' */
.uil-ban:before {
  content: '\e870'; }

/* '' */
.uil-bars:before {
  content: '\e871'; }

/* '' */
.uil-baseball-ball:before {
  content: '\e872'; }

/* '' */
.uil-basketball-hoop:before {
  content: '\e873'; }

/* '' */
.uil-basketball:before {
  content: '\e874'; }

/* '' */
.uil-bath:before {
  content: '\e875'; }

/* '' */
.uil-battery-bolt:before {
  content: '\e876'; }

/* '' */
.uil-battery-empty:before {
  content: '\e877'; }

/* '' */
.uil-bed-double:before {
  content: '\e878'; }

/* '' */
.uil-bed:before {
  content: '\e879'; }

/* '' */
.uil-behance-alt:before {
  content: '\e87a'; }

/* '' */
.uil-behance:before {
  content: '\e87b'; }

/* '' */
.uil-bell-school:before {
  content: '\e87c'; }

/* '' */
.uil-bell-slash:before {
  content: '\e87d'; }

/* '' */
.uil-bell:before {
  content: '\e87e'; }

/* '' */
.uil-bill:before {
  content: '\e87f'; }

/* '' */
.uil-bitcoin-alt:before {
  content: '\e880'; }

/* '' */
.uil-bitcoin-bold:before {
  content: '\e881'; }

/* '' */
.uil-bitcoin-circle:before {
  content: '\e882'; }

/* '' */
.uil-bitcoin:before {
  content: '\e883'; }

/* '' */
.uil-black-berry:before {
  content: '\e884'; }

/* '' */
.uil-blogger-alt:before {
  content: '\e885'; }

/* '' */
.uil-blogger:before {
  content: '\e886'; }

/* '' */
.uil-bluetooth-b:before {
  content: '\e887'; }

/* '' */
.uil-bold:before {
  content: '\e888'; }

/* '' */
.uil-bolt-alt:before {
  content: '\e889'; }

/* '' */
.uil-bolt-slash:before {
  content: '\e88a'; }

/* '' */
.uil-bolt:before {
  content: '\e88b'; }

/* '' */
.uil-book-alt:before {
  content: '\e88c'; }

/* '' */
.uil-book-medical:before {
  content: '\e88d'; }

/* '' */
.uil-book-open:before {
  content: '\e88e'; }

/* '' */
.uil-book-reader:before {
  content: '\e88f'; }

/* '' */
.uil-book:before {
  content: '\e890'; }

/* '' */
.uil-bookmark-full:before {
  content: '\e891'; }

/* '' */
.uil-bookmark:before {
  content: '\e892'; }

/* '' */
.uil-books:before {
  content: '\e893'; }

/* '' */
.uil-boombox:before {
  content: '\e894'; }

/* '' */
.uil-border-alt:before {
  content: '\e895'; }

/* '' */
.uil-border-bottom:before {
  content: '\e896'; }

/* '' */
.uil-border-clear:before {
  content: '\e897'; }

/* '' */
.uil-border-horizontal:before {
  content: '\e898'; }

/* '' */
.uil-border-inner:before {
  content: '\e899'; }

/* '' */
.uil-border-left:before {
  content: '\e89a'; }

/* '' */
.uil-border-out:before {
  content: '\e89b'; }

/* '' */
.uil-border-right:before {
  content: '\e89c'; }

/* '' */
.uil-border-top:before {
  content: '\e89d'; }

/* '' */
.uil-border-vertical:before {
  content: '\e89e'; }

/* '' */
.uil-bowling-ball:before {
  content: '\e89f'; }

/* '' */
.uil-box:before {
  content: '\e8a0'; }

/* '' */
.uil-briefcase-alt:before {
  content: '\e8a1'; }

/* '' */
.uil-briefcase:before {
  content: '\e8a2'; }

/* '' */
.uil-bright:before {
  content: '\e8a3'; }

/* '' */
.uil-brightness-empty:before {
  content: '\e8a4'; }

/* '' */
.uil-brightness-half:before {
  content: '\e8a5'; }

/* '' */
.uil-brightness-low:before {
  content: '\e8a6'; }

/* '' */
.uil-brightness-minus:before {
  content: '\e8a7'; }

/* '' */
.uil-brightness-plus:before {
  content: '\e8a8'; }

/* '' */
.uil-brightness:before {
  content: '\e8a9'; }

/* '' */
.uil-bring-bottom:before {
  content: '\e8aa'; }

/* '' */
.uil-bring-front:before {
  content: '\e8ab'; }

/* '' */
.uil-brush-alt:before {
  content: '\e8ac'; }

/* '' */
.uil-bug:before {
  content: '\e8ad'; }

/* '' */
.uil-building:before {
  content: '\e8ae'; }

/* '' */
.uil-bullseye:before {
  content: '\e8af'; }

/* '' */
.uil-bus-alt:before {
  content: '\e8b0'; }

/* '' */
.uil-bus-school:before {
  content: '\e8b1'; }

/* '' */
.uil-bus:before {
  content: '\e8b2'; }

/* '' */
.uil-calcualtor:before {
  content: '\e8b3'; }

/* '' */
.uil-calculator-alt:before {
  content: '\e8b4'; }

/* '' */
.uil-calculator:before {
  content: '\e8b5'; }

/* '' */
.uil-calendar-alt:before {
  content: '\e8b6'; }

/* '' */
.uil-calendar-slash:before {
  content: '\e8b7'; }

/* '' */
.uil-calender:before {
  content: '\e8b8'; }

/* '' */
.uil-calling:before {
  content: '\e8b9'; }

/* '' */
.uil-camera-change:before {
  content: '\e8ba'; }

/* '' */
.uil-camera-plus:before {
  content: '\e8bb'; }

/* '' */
.uil-camera-slash:before {
  content: '\e8bc'; }

/* '' */
.uil-camera:before {
  content: '\e8bd'; }

/* '' */
.uil-cancel:before {
  content: '\e8be'; }

/* '' */
.uil-capsule:before {
  content: '\e8bf'; }

/* '' */
.uil-capture:before {
  content: '\e8c0'; }

/* '' */
.uil-car-sideview:before {
  content: '\e8c1'; }

/* '' */
.uil-car-slash:before {
  content: '\e8c2'; }

/* '' */
.uil-car-wash:before {
  content: '\e8c3'; }

/* '' */
.uil-car:before {
  content: '\e8c4'; }

/* '' */
.uil-card-atm:before {
  content: '\e8c5'; }

/* '' */
.uil-caret-right:before {
  content: '\e8c6'; }

/* '' */
.uil-cart:before {
  content: '\e8c7'; }

/* '' */
.uil-cell:before {
  content: '\e8c8'; }

/* '' */
.uil-celsius:before {
  content: '\e8c9'; }

/* '' */
.uil-chart-bar-alt:before {
  content: '\e8ca'; }

/* '' */
.uil-chart-bar:before {
  content: '\e8cb'; }

/* '' */
.uil-chart-down:before {
  content: '\e8cc'; }

/* '' */
.uil-chart-growth-alt:before {
  content: '\e8cd'; }

/* '' */
.uil-chart-growth:before {
  content: '\e8ce'; }

/* '' */
.uil-chart-line:before {
  content: '\e8cf'; }

/* '' */
.uil-chart-pie-alt:before {
  content: '\e8d0'; }

/* '' */
.uil-chart-pie:before {
  content: '\e8d1'; }

/* '' */
.uil-chart:before {
  content: '\e8d2'; }

/* '' */
.uil-chat-bubble-user:before {
  content: '\e8d3'; }

/* '' */
.uil-chat-info:before {
  content: '\e8d4'; }

/* '' */
.uil-chat:before {
  content: '\e8d5'; }

/* '' */
.uil-check-circle:before {
  content: '\e8d6'; }

/* '' */
.uil-check-square:before {
  content: '\e8d7'; }

/* '' */
.uil-check:before {
  content: '\e8d8'; }

/* '' */
.uil-circle-layer:before {
  content: '\e8d9'; }

/* '' */
.uil-circle:before {
  content: '\e8da'; }

/* '' */
.uil-circuit:before {
  content: '\e8db'; }

/* '' */
.uil-clapper-board:before {
  content: '\e8dc'; }

/* '' */
.uil-clipboard-alt:before {
  content: '\e8dd'; }

/* '' */
.uil-clipboard-blank:before {
  content: '\e8de'; }

/* '' */
.uil-clipboard-notes:before {
  content: '\e8df'; }

/* '' */
.uil-clipboard:before {
  content: '\e8e0'; }

/* '' */
.uil-clock-eight:before {
  content: '\e8e1'; }

/* '' */
.uil-clock-five:before {
  content: '\e8e2'; }

/* '' */
.uil-clock-nine:before {
  content: '\e8e3'; }

/* '' */
.uil-clock-seven:before {
  content: '\e8e4'; }

/* '' */
.uil-clock-ten:before {
  content: '\e8e5'; }

/* '' */
.uil-clock-three:before {
  content: '\e8e6'; }

/* '' */
.uil-clock-two:before {
  content: '\e8e7'; }

/* '' */
.uil-clock:before {
  content: '\e8e8'; }

/* '' */
.uil-closed-captioning:before {
  content: '\e8e9'; }

/* '' */
.uil-cloud-block:before {
  content: '\e8ea'; }

/* '' */
.uil-cloud-bookmark:before {
  content: '\e8eb'; }

/* '' */
.uil-cloud-check:before {
  content: '\e8ec'; }

/* '' */
.uil-cloud-computing:before {
  content: '\e8ed'; }

/* '' */
.uil-cloud-data-connection:before {
  content: '\e8ee'; }

/* '' */
.uil-cloud-database-tree:before {
  content: '\e8ef'; }

/* '' */
.uil-cloud-download:before {
  content: '\e8f0'; }

/* '' */
.uil-cloud-drizzle:before {
  content: '\e8f1'; }

/* '' */
.uil-cloud-exclamation:before {
  content: '\e8f2'; }

/* '' */
.uil-cloud-hail:before {
  content: '\e8f3'; }

/* '' */
.uil-cloud-heart:before {
  content: '\e8f4'; }

/* '' */
.uil-cloud-info:before {
  content: '\e8f5'; }

/* '' */
.uil-cloud-lock:before {
  content: '\e8f6'; }

/* '' */
.uil-cloud-meatball:before {
  content: '\e8f7'; }

/* '' */
.uil-cloud-moon-hail:before {
  content: '\e8f8'; }

/* '' */
.uil-cloud-moon-meatball:before {
  content: '\e8f9'; }

/* '' */
.uil-cloud-moon-rain:before {
  content: '\e8fa'; }

/* '' */
.uil-cloud-moon-showers:before {
  content: '\e8fb'; }

/* '' */
.uil-cloud-moon:before {
  content: '\e8fc'; }

/* '' */
.uil-cloud-question:before {
  content: '\e8fd'; }

/* '' */
.uil-cloud-rain-sun:before {
  content: '\e8fe'; }

/* '' */
.uil-cloud-rain:before {
  content: '\e8ff'; }

/* '' */
.uil-cloud-redo:before {
  content: '\e900'; }

/* '' */
.uil-cloud-set:before {
  content: '\e901'; }

/* '' */
.uil-cloud-share:before {
  content: '\e902'; }

/* '' */
.uil-cloud-shield:before {
  content: '\e903'; }

/* '' */
.uil-cloud-showers-alt:before {
  content: '\e904'; }

/* '' */
.uil-cloud-showers-heavy:before {
  content: '\e905'; }

/* '' */
.uil-cloud-showers:before {
  content: '\e906'; }

/* '' */
.uil-cloud-slash:before {
  content: '\e907'; }

/* '' */
.uil-cloud-sun-hail:before {
  content: '\e908'; }

/* '' */
.uil-cloud-sun-meatball:before {
  content: '\e909'; }

/* '' */
.uil-cloud-sun-rain-alt:before {
  content: '\e90a'; }

/* '' */
.uil-cloud-sun-rain:before {
  content: '\e90b'; }

/* '' */
.uil-cloud-sun-tear:before {
  content: '\e90c'; }

/* '' */
.uil-cloud-sun:before {
  content: '\e90d'; }

/* '' */
.uil-cloud-times:before {
  content: '\e90e'; }

/* '' */
.uil-cloud-unlock:before {
  content: '\e90f'; }

/* '' */
.uil-cloud-upload:before {
  content: '\e910'; }

/* '' */
.uil-cloud-wifi:before {
  content: '\e911'; }

/* '' */
.uil-cloud-wind:before {
  content: '\e912'; }

/* '' */
.uil-cloud:before {
  content: '\e913'; }

/* '' */
.uil-clouds:before {
  content: '\e914'; }

/* '' */
.uil-club:before {
  content: '\e915'; }

/* '' */
.uil-code:before {
  content: '\e916'; }

/* '' */
.uil-coffee:before {
  content: '\e917'; }

/* '' */
.uil-cog:before {
  content: '\e918'; }

/* '' */
.uil-coins:before {
  content: '\e919'; }

/* '' */
.uil-columns:before {
  content: '\e91a'; }

/* '' */
.uil-comment-alt-block:before {
  content: '\e91b'; }

/* '' */
.uil-comment-alt-chart-lines:before {
  content: '\e91c'; }

/* '' */
.uil-comment-alt-check:before {
  content: '\e91d'; }

/* '' */
.uil-comment-alt-dots:before {
  content: '\e91e'; }

/* '' */
.uil-comment-alt-download:before {
  content: '\e91f'; }

/* '' */
.uil-comment-alt-edit:before {
  content: '\e920'; }

/* '' */
.uil-comment-alt-exclamation:before {
  content: '\e921'; }

/* '' */
.uil-comment-alt-heart:before {
  content: '\e922'; }

/* '' */
.uil-comment-alt-image:before {
  content: '\e923'; }

/* '' */
.uil-comment-alt-info:before {
  content: '\e924'; }

/* '' */
.uil-comment-alt-lines:before {
  content: '\e925'; }

/* '' */
.uil-comment-alt-lock:before {
  content: '\e926'; }

/* '' */
.uil-comment-alt-medical:before {
  content: '\e927'; }

/* '' */
.uil-comment-alt-message:before {
  content: '\e928'; }

/* '' */
.uil-comment-alt-notes:before {
  content: '\e929'; }

/* '' */
.uil-comment-alt-plus:before {
  content: '\e92a'; }

/* '' */
.uil-comment-alt-question:before {
  content: '\e92b'; }

/* '' */
.uil-comment-alt-redo:before {
  content: '\e92c'; }

/* '' */
.uil-comment-alt-search:before {
  content: '\e92d'; }

/* '' */
.uil-comment-alt-share:before {
  content: '\e92e'; }

/* '' */
.uil-comment-alt-shield:before {
  content: '\e92f'; }

/* '' */
.uil-comment-alt-slash:before {
  content: '\e930'; }

/* '' */
.uil-comment-alt-upload:before {
  content: '\e931'; }

/* '' */
.uil-comment-alt-verify:before {
  content: '\e932'; }

/* '' */
.uil-comment-alt:before {
  content: '\e933'; }

/* '' */
.uil-comment-block:before {
  content: '\e934'; }

/* '' */
.uil-comment-chart-line:before {
  content: '\e935'; }

/* '' */
.uil-comment-check:before {
  content: '\e936'; }

/* '' */
.uil-comment-dots:before {
  content: '\e937'; }

/* '' */
.uil-comment-download:before {
  content: '\e938'; }

/* '' */
.uil-comment-edit:before {
  content: '\e939'; }

/* '' */
.uil-comment-exclamation:before {
  content: '\e93a'; }

/* '' */
.uil-comment-heart:before {
  content: '\e93b'; }

/* '' */
.uil-comment-image:before {
  content: '\e93c'; }

/* '' */
.uil-comment-info-alt:before {
  content: '\e93d'; }

/* '' */
.uil-comment-info:before {
  content: '\e93e'; }

/* '' */
.uil-comment-lines:before {
  content: '\e93f'; }

/* '' */
.uil-comment-lock:before {
  content: '\e940'; }

/* '' */
.uil-comment-medical:before {
  content: '\e941'; }

/* '' */
.uil-comment-message:before {
  content: '\e942'; }

/* '' */
.uil-comment-notes:before {
  content: '\e943'; }

/* '' */
.uil-comment-plus:before {
  content: '\e944'; }

/* '' */
.uil-comment-question:before {
  content: '\e945'; }

/* '' */
.uil-comment-redo:before {
  content: '\e946'; }

/* '' */
.uil-comment-search:before {
  content: '\e947'; }

/* '' */
.uil-comment-share:before {
  content: '\e948'; }

/* '' */
.uil-comment-shield:before {
  content: '\e949'; }

/* '' */
.uil-comment-slash:before {
  content: '\e94a'; }

/* '' */
.uil-comment-upload:before {
  content: '\e94b'; }

/* '' */
.uil-comment-verify:before {
  content: '\e94c'; }

/* '' */
.uil-comment:before {
  content: '\e94d'; }

/* '' */
.uil-comments-alt:before {
  content: '\e94e'; }

/* '' */
.uil-comments:before {
  content: '\e94f'; }

/* '' */
.uil-commnet-alt-slash:before {
  content: '\e950'; }

/* '' */
.uil-compact-disc:before {
  content: '\e951'; }

/* '' */
.uil-compass:before {
  content: '\e952'; }

/* '' */
.uil-compress-alt-left:before {
  content: '\e953'; }

/* '' */
.uil-compress-alt:before {
  content: '\e954'; }

/* '' */
.uil-compress-arrows:before {
  content: '\e955'; }

/* '' */
.uil-compress-lines:before {
  content: '\e956'; }

/* '' */
.uil-compress-point:before {
  content: '\e957'; }

/* '' */
.uil-compress-v:before {
  content: '\e958'; }

/* '' */
.uil-compress:before {
  content: '\e959'; }

/* '' */
.uil-computer-mouse:before {
  content: '\e95a'; }

/* '' */
.uil-confused:before {
  content: '\e95b'; }

/* '' */
.uil-constructor:before {
  content: '\e95c'; }

/* '' */
.uil-copy-alt:before {
  content: '\e95d'; }

/* '' */
.uil-copy-landscape:before {
  content: '\e95e'; }

/* '' */
.uil-copy:before {
  content: '\e95f'; }

/* '' */
.uil-copyright:before {
  content: '\e960'; }

/* '' */
.uil-corner-down-left:before {
  content: '\e961'; }

/* '' */
.uil-corner-down-right-alt:before {
  content: '\e962'; }

/* '' */
.uil-corner-down-right:before {
  content: '\e963'; }

/* '' */
.uil-corner-left-down:before {
  content: '\e964'; }

/* '' */
.uil-corner-right-down:before {
  content: '\e965'; }

/* '' */
.uil-corner-up-left-alt:before {
  content: '\e966'; }

/* '' */
.uil-corner-up-left:before {
  content: '\e967'; }

/* '' */
.uil-corner-up-right-alt:before {
  content: '\e968'; }

/* '' */
.uil-corner-up-right:before {
  content: '\e969'; }

/* '' */
.uil-creative-commons-pd-alt:before {
  content: '\e96a'; }

/* '' */
.uil-creative-commons-pd:before {
  content: '\e96b'; }

/* '' */
.uil-crockery:before {
  content: '\e96c'; }

/* '' */
.uil-crop-alt-rotate-left:before {
  content: '\e96d'; }

/* '' */
.uil-crop-alt-rotate-right:before {
  content: '\e96e'; }

/* '' */
.uil-crop-alt:before {
  content: '\e96f'; }

/* '' */
.uil-crosshair-alt:before {
  content: '\e970'; }

/* '' */
.uil-crosshair:before {
  content: '\e971'; }

/* '' */
.uil-crosshairs:before {
  content: '\e972'; }

/* '' */
.uil-cube:before {
  content: '\e973'; }

/* '' */
.uil-dashboard:before {
  content: '\e974'; }

/* '' */
.uil-data-sharing:before {
  content: '\e975'; }

/* '' */
.uil-database-alt:before {
  content: '\e976'; }

/* '' */
.uil-database:before {
  content: '\e977'; }

/* '' */
.uil-desert:before {
  content: '\e978'; }

/* '' */
.uil-desktop-alt-slash:before {
  content: '\e979'; }

/* '' */
.uil-desktop-alt:before {
  content: '\e97a'; }

/* '' */
.uil-desktop-cloud-alt:before {
  content: '\e97b'; }

/* '' */
.uil-desktop-slash:before {
  content: '\e97c'; }

/* '' */
.uil-desktop:before {
  content: '\e97d'; }

/* '' */
.uil-dialpad-alt:before {
  content: '\e97e'; }

/* '' */
.uil-dialpad:before {
  content: '\e97f'; }

/* '' */
.uil-diamond:before {
  content: '\e980'; }

/* '' */
.uil-diary-alt:before {
  content: '\e981'; }

/* '' */
.uil-diary:before {
  content: '\e982'; }

/* '' */
.uil-dice-five:before {
  content: '\e983'; }

/* '' */
.uil-dice-four:before {
  content: '\e984'; }

/* '' */
.uil-dice-one:before {
  content: '\e985'; }

/* '' */
.uil-dice-six:before {
  content: '\e986'; }

/* '' */
.uil-dice-three:before {
  content: '\e987'; }

/* '' */
.uil-dice-two:before {
  content: '\e988'; }

/* '' */
.uil-direction:before {
  content: '\e989'; }

/* '' */
.uil-directions:before {
  content: '\e98a'; }

/* '' */
.uil-dizzy-meh:before {
  content: '\e98b'; }

/* '' */
.uil-dna:before {
  content: '\e98c'; }

/* '' */
.uil-document-layout-center:before {
  content: '\e98d'; }

/* '' */
.uil-document-layout-left:before {
  content: '\e98e'; }

/* '' */
.uil-document-layout-right:before {
  content: '\e98f'; }

/* '' */
.uil-document:before {
  content: '\e990'; }

/* '' */
.uil-dollar-alt:before {
  content: '\e991'; }

/* '' */
.uil-dollar-sign-alt:before {
  content: '\e992'; }

/* '' */
.uil-dollar-sign:before {
  content: '\e993'; }

/* '' */
.uil-down-arrow:before {
  content: '\e994'; }

/* '' */
.uil-download-alt:before {
  content: '\e995'; }

/* '' */
.uil-dribbble:before {
  content: '\e996'; }

/* '' */
.uil-drill:before {
  content: '\e997'; }

/* '' */
.uil-dropbox:before {
  content: '\e998'; }

/* '' */
.uil-dumbbell:before {
  content: '\e999'; }

/* '' */
.uil-ear:before {
  content: '\e99a'; }

/* '' */
.uil-edit-alt:before {
  content: '\e99b'; }

/* '' */
.uil-edit:before {
  content: '\e99c'; }

/* '' */
.uil-ellipsis-h:before {
  content: '\e99d'; }

/* '' */
.uil-ellipsis-v:before {
  content: '\e99e'; }

/* '' */
.uil-emoji:before {
  content: '\e99f'; }

/* '' */
.uil-enter:before {
  content: '\e9a0'; }

/* '' */
.uil-entry:before {
  content: '\e9a1'; }

/* '' */
.uil-envelope-add:before {
  content: '\e9a2'; }

/* '' */
.uil-envelope-alt:before {
  content: '\e9a3'; }

/* '' */
.uil-envelope-block:before {
  content: '\e9a4'; }

/* '' */
.uil-envelope-bookmark:before {
  content: '\e9a5'; }

/* '' */
.uil-envelope-check:before {
  content: '\e9a6'; }

/* '' */
.uil-envelope-download-alt:before {
  content: '\e9a7'; }

/* '' */
.uil-envelope-download:before {
  content: '\e9a8'; }

/* '' */
.uil-envelope-edit:before {
  content: '\e9a9'; }

/* '' */
.uil-envelope-exclamation:before {
  content: '\e9aa'; }

/* '' */
.uil-envelope-heart:before {
  content: '\e9ab'; }

/* '' */
.uil-envelope-info:before {
  content: '\e9ac'; }

/* '' */
.uil-envelope-lock:before {
  content: '\e9ad'; }

/* '' */
.uil-envelope-minus:before {
  content: '\e9ae'; }

/* '' */
.uil-envelope-open:before {
  content: '\e9af'; }

/* '' */
.uil-envelope-question:before {
  content: '\e9b0'; }

/* '' */
.uil-envelope-receive:before {
  content: '\e9b1'; }

/* '' */
.uil-envelope-redo:before {
  content: '\e9b2'; }

/* '' */
.uil-envelope-search:before {
  content: '\e9b3'; }

/* '' */
.uil-envelope-send:before {
  content: '\e9b4'; }

/* '' */
.uil-envelope-share:before {
  content: '\e9b5'; }

/* '' */
.uil-envelope-shield:before {
  content: '\e9b6'; }

/* '' */
.uil-envelope-star:before {
  content: '\e9b7'; }

/* '' */
.uil-envelope-times:before {
  content: '\e9b8'; }

/* '' */
.uil-envelope-upload-alt:before {
  content: '\e9b9'; }

/* '' */
.uil-envelope-upload:before {
  content: '\e9ba'; }

/* '' */
.uil-envelope:before {
  content: '\e9bb'; }

/* '' */
.uil-envelopes:before {
  content: '\e9bc'; }

/* '' */
.uil-equal-circle:before {
  content: '\e9bd'; }

/* '' */
.uil-euro-circle:before {
  content: '\e9be'; }

/* '' */
.uil-euro:before {
  content: '\e9bf'; }

/* '' */
.uil-exchange-alt:before {
  content: '\e9c0'; }

/* '' */
.uil-exchange:before {
  content: '\e9c1'; }

/* '' */
.uil-exclamation-circle:before {
  content: '\e9c2'; }

/* '' */
.uil-exclamation-octagon:before {
  content: '\e9c3'; }

/* '' */
.uil-exclamation-triangle:before {
  content: '\e9c4'; }

/* '' */
.uil-exclude:before {
  content: '\e9c5'; }

/* '' */
.uil-exit:before {
  content: '\e9c6'; }

/* '' */
.uil-expand-alt:before {
  content: '\e9c7'; }

/* '' */
.uil-expand-arrows-alt:before {
  content: '\e9c8'; }

/* '' */
.uil-expand-arrows:before {
  content: '\e9c9'; }

/* '' */
.uil-expand-from-corner:before {
  content: '\e9ca'; }

/* '' */
.uil-expand-left:before {
  content: '\e9cb'; }

/* '' */
.uil-expand-right:before {
  content: '\e9cc'; }

/* '' */
.uil-export:before {
  content: '\e9cd'; }

/* '' */
.uil-exposure-alt:before {
  content: '\e9ce'; }

/* '' */
.uil-exposure-increase:before {
  content: '\e9cf'; }

/* '' */
.uil-external-link-alt:before {
  content: '\e9d0'; }

/* '' */
.uil-eye-slash:before {
  content: '\e9d1'; }

/* '' */
.uil-eye:before {
  content: '\e9d2'; }

/* '' */
.uil-facebook-f:before {
  content: '\e9d3'; }

/* '' */
.uil-facebook-messenger-alt:before {
  content: '\e9d4'; }

/* '' */
.uil-facebook-messenger:before {
  content: '\e9d5'; }

/* '' */
.uil-facebook:before {
  content: '\e9d6'; }

/* '' */
.uil-fahrenheit:before {
  content: '\e9d7'; }

/* '' */
.uil-fast-mail-alt:before {
  content: '\e9d8'; }

/* '' */
.uil-fast-mail:before {
  content: '\e9d9'; }

/* '' */
.uil-favorite:before {
  content: '\e9da'; }

/* '' */
.uil-feedback:before {
  content: '\e9db'; }

/* '' */
.uil-file-alt:before {
  content: '\e9dc'; }

/* '' */
.uil-file-blank:before {
  content: '\e9dd'; }

/* '' */
.uil-file-block-alt:before {
  content: '\e9de'; }

/* '' */
.uil-file-bookmark-alt:before {
  content: '\e9df'; }

/* '' */
.uil-file-check-alt:before {
  content: '\e9e0'; }

/* '' */
.uil-file-check:before {
  content: '\e9e1'; }

/* '' */
.uil-file-contract-dollar:before {
  content: '\e9e2'; }

/* '' */
.uil-file-copy-alt:before {
  content: '\e9e3'; }

/* '' */
.uil-file-download-alt:before {
  content: '\e9e4'; }

/* '' */
.uil-file-download:before {
  content: '\e9e5'; }

/* '' */
.uil-file-edit-alt:before {
  content: '\e9e6'; }

/* '' */
.uil-file-exclamation-alt:before {
  content: '\e9e7'; }

/* '' */
.uil-file-exclamation:before {
  content: '\e9e8'; }

/* '' */
.uil-file-heart:before {
  content: '\e9e9'; }

/* '' */
.uil-file-info-alt:before {
  content: '\e9ea'; }

/* '' */
.uil-file-landscape-alt:before {
  content: '\e9eb'; }

/* '' */
.uil-file-landscape:before {
  content: '\e9ec'; }

/* '' */
.uil-file-lanscape-slash:before {
  content: '\e9ed'; }

/* '' */
.uil-file-lock-alt:before {
  content: '\e9ee'; }

/* '' */
.uil-file-medical-alt:before {
  content: '\e9ef'; }

/* '' */
.uil-file-medical:before {
  content: '\e9f0'; }

/* '' */
.uil-file-minus-alt:before {
  content: '\e9f1'; }

/* '' */
.uil-file-minus:before {
  content: '\e9f2'; }

/* '' */
.uil-file-network:before {
  content: '\e9f3'; }

/* '' */
.uil-file-plus-alt:before {
  content: '\e9f4'; }

/* '' */
.uil-file-plus:before {
  content: '\e9f5'; }

/* '' */
.uil-file-question-alt:before {
  content: '\e9f6'; }

/* '' */
.uil-file-question:before {
  content: '\e9f7'; }

/* '' */
.uil-file-redo-alt:before {
  content: '\e9f8'; }

/* '' */
.uil-file-search-alt:before {
  content: '\e9f9'; }

/* '' */
.uil-file-share-alt:before {
  content: '\e9fa'; }

/* '' */
.uil-file-shield-alt:before {
  content: '\e9fb'; }

/* '' */
.uil-file-slash:before {
  content: '\e9fc'; }

/* '' */
.uil-file-times-alt:before {
  content: '\e9fd'; }

/* '' */
.uil-file-times:before {
  content: '\e9fe'; }

/* '' */
.uil-file-upload-alt:before {
  content: '\e9ff'; }

/* '' */
.uil-file-upload:before {
  content: '\ea00'; }

/* '' */
.uil-file:before {
  content: '\ea01'; }

/* '' */
.uil-files-landscapes-alt:before {
  content: '\ea02'; }

/* '' */
.uil-files-landscapes:before {
  content: '\ea03'; }

/* '' */
.uil-film:before {
  content: '\ea04'; }

/* '' */
.uil-filter-slash:before {
  content: '\ea05'; }

/* '' */
.uil-filter:before {
  content: '\ea06'; }

/* '' */
.uil-fire:before {
  content: '\ea07'; }

/* '' */
.uil-flask-potion:before {
  content: '\ea08'; }

/* '' */
.uil-flask:before {
  content: '\ea09'; }

/* '' */
.uil-flip-h-alt:before {
  content: '\ea0a'; }

/* '' */
.uil-flip-h:before {
  content: '\ea0b'; }

/* '' */
.uil-flip-v-alt:before {
  content: '\ea0c'; }

/* '' */
.uil-flip-v:before {
  content: '\ea0d'; }

/* '' */
.uil-flower:before {
  content: '\ea0e'; }

/* '' */
.uil-focus-add:before {
  content: '\ea0f'; }

/* '' */
.uil-focus-target:before {
  content: '\ea10'; }

/* '' */
.uil-focus:before {
  content: '\ea11'; }

/* '' */
.uil-folder-check:before {
  content: '\ea12'; }

/* '' */
.uil-folder-download:before {
  content: '\ea13'; }

/* '' */
.uil-folder-exclamation:before {
  content: '\ea14'; }

/* '' */
.uil-folder-heart:before {
  content: '\ea15'; }

/* '' */
.uil-folder-info:before {
  content: '\ea16'; }

/* '' */
.uil-folder-lock:before {
  content: '\ea17'; }

/* '' */
.uil-folder-medical:before {
  content: '\ea18'; }

/* '' */
.uil-folder-minus:before {
  content: '\ea19'; }

/* '' */
.uil-folder-network:before {
  content: '\ea1a'; }

/* '' */
.uil-folder-plus:before {
  content: '\ea1b'; }

/* '' */
.uil-folder-question:before {
  content: '\ea1c'; }

/* '' */
.uil-folder-slash:before {
  content: '\ea1d'; }

/* '' */
.uil-folder-times:before {
  content: '\ea1e'; }

/* '' */
.uil-folder-upload:before {
  content: '\ea1f'; }

/* '' */
.uil-folder:before {
  content: '\ea20'; }

/* '' */
.uil-food:before {
  content: '\ea21'; }

/* '' */
.uil-football-american:before {
  content: '\ea22'; }

/* '' */
.uil-football-ball:before {
  content: '\ea23'; }

/* '' */
.uil-football:before {
  content: '\ea24'; }

/* '' */
.uil-forecastcloud-moon-tear:before {
  content: '\ea25'; }

/* '' */
.uil-forwaded-call:before {
  content: '\ea26'; }

/* '' */
.uil-forward:before {
  content: '\ea27'; }

/* '' */
.uil-frown:before {
  content: '\ea28'; }

/* '' */
.uil-game-structure:before {
  content: '\ea29'; }

/* '' */
.uil-game:before {
  content: '\ea2a'; }

/* '' */
.uil-gift:before {
  content: '\ea2b'; }

/* '' */
.uil-github-alt:before {
  content: '\ea2c'; }

/* '' */
.uil-github:before {
  content: '\ea2d'; }

/* '' */
.uil-glass-martini-alt-slash:before {
  content: '\ea2e'; }

/* '' */
.uil-glass-martini-alt:before {
  content: '\ea2f'; }

/* '' */
.uil-glass-martini:before {
  content: '\ea30'; }

/* '' */
.uil-glass-tea:before {
  content: '\ea31'; }

/* '' */
.uil-glass:before {
  content: '\ea32'; }

/* '' */
.uil-globe:before {
  content: '\ea33'; }

/* '' */
.uil-gold:before {
  content: '\ea34'; }

/* '' */
.uil-google-drive-alt:before {
  content: '\ea35'; }

/* '' */
.uil-google-drive:before {
  content: '\ea36'; }

/* '' */
.uil-google-hangouts-alt:before {
  content: '\ea37'; }

/* '' */
.uil-google-hangouts:before {
  content: '\ea38'; }

/* '' */
.uil-google-play:before {
  content: '\ea39'; }

/* '' */
.uil-google:before {
  content: '\ea3a'; }

/* '' */
.uil-graduation-hat:before {
  content: '\ea3b'; }

/* '' */
.uil-graph-bar:before {
  content: '\ea3c'; }

/* '' */
.uil-grid:before {
  content: '\ea3d'; }

/* '' */
.uil-grids:before {
  content: '\ea3e'; }

/* '' */
.uil-grin-tongue-wink-alt:before {
  content: '\ea3f'; }

/* '' */
.uil-grin-tongue-wink:before {
  content: '\ea40'; }

/* '' */
.uil-grin:before {
  content: '\ea41'; }

/* '' */
.uil-grip-horizontal-line:before {
  content: '\ea42'; }

/* '' */
.uil-hdd:before {
  content: '\ea43'; }

/* '' */
.uil-headphones-alt:before {
  content: '\ea44'; }

/* '' */
.uil-headphones:before {
  content: '\ea45'; }

/* '' */
.uil-heart-alt:before {
  content: '\ea46'; }

/* '' */
.uil-heart-medical:before {
  content: '\ea47'; }

/* '' */
.uil-heart-rate:before {
  content: '\ea48'; }

/* '' */
.uil-heart-sign:before {
  content: '\ea49'; }

/* '' */
.uil-heart:before {
  content: '\ea4a'; }

/* '' */
.uil-heartbeat:before {
  content: '\ea4b'; }

/* '' */
.uil-history-alt:before {
  content: '\ea4c'; }

/* '' */
.uil-history:before {
  content: '\ea4d'; }

/* '' */
.uil-home-alt:before {
  content: '\ea4e'; }

/* '' */
.uil-home:before {
  content: '\ea4f'; }

/* '' */
.uil-horizontal-align-center:before {
  content: '\ea50'; }

/* '' */
.uil-horizontal-align-left:before {
  content: '\ea51'; }

/* '' */
.uil-horizontal-align-right:before {
  content: '\ea52'; }

/* '' */
.uil-horizontal-distribution-center:before {
  content: '\ea53'; }

/* '' */
.uil-horizontal-distribution-left:before {
  content: '\ea54'; }

/* '' */
.uil-horizontal-distribution-right:before {
  content: '\ea55'; }

/* '' */
.uil-hourglass:before {
  content: '\ea56'; }

/* '' */
.uil-html3-alt:before {
  content: '\ea57'; }

/* '' */
.uil-html3:before {
  content: '\ea58'; }

/* '' */
.uil-hunting:before {
  content: '\ea59'; }

/* '' */
.uil-image-alt-slash:before {
  content: '\ea5a'; }

/* '' */
.uil-image-block:before {
  content: '\ea5b'; }

/* '' */
.uil-image-broken:before {
  content: '\ea5c'; }

/* '' */
.uil-image-check:before {
  content: '\ea5d'; }

/* '' */
.uil-image-download:before {
  content: '\ea5e'; }

/* '' */
.uil-image-edit:before {
  content: '\ea5f'; }

/* '' */
.uil-image-lock:before {
  content: '\ea60'; }

/* '' */
.uil-image-minus:before {
  content: '\ea61'; }

/* '' */
.uil-image-plus:before {
  content: '\ea62'; }

/* '' */
.uil-image-question:before {
  content: '\ea63'; }

/* '' */
.uil-image-redo:before {
  content: '\ea64'; }

/* '' */
.uil-image-resize-landscape:before {
  content: '\ea65'; }

/* '' */
.uil-image-resize-square:before {
  content: '\ea66'; }

/* '' */
.uil-image-search:before {
  content: '\ea67'; }

/* '' */
.uil-image-share:before {
  content: '\ea68'; }

/* '' */
.uil-image-shield:before {
  content: '\ea69'; }

/* '' */
.uil-image-slash:before {
  content: '\ea6a'; }

/* '' */
.uil-image-times:before {
  content: '\ea6b'; }

/* '' */
.uil-image-upload:before {
  content: '\ea6c'; }

/* '' */
.uil-image-v:before {
  content: '\ea6d'; }

/* '' */
.uil-image:before {
  content: '\ea6e'; }

/* '' */
.uil-images:before {
  content: '\ea6f'; }

/* '' */
.uil-import:before {
  content: '\ea70'; }

/* '' */
.uil-incoming-call:before {
  content: '\ea71'; }

/* '' */
.uil-info-circle:before {
  content: '\ea72'; }

/* '' */
.uil-instagram-alt:before {
  content: '\ea73'; }

/* '' */
.uil-instagram:before {
  content: '\ea74'; }

/* '' */
.uil-intercom-alt:before {
  content: '\ea75'; }

/* '' */
.uil-intercom:before {
  content: '\ea76'; }

/* '' */
.uil-invoice:before {
  content: '\ea77'; }

/* '' */
.uil-italic:before {
  content: '\ea78'; }

/* '' */
.uil-jackhammer:before {
  content: '\ea79'; }

/* '' */
.uil-java-script:before {
  content: '\ea7a'; }

/* '' */
.uil-kayak:before {
  content: '\ea7b'; }

/* '' */
.uil-key-skeleton-alt:before {
  content: '\ea7c'; }

/* '' */
.uil-key-skeleton:before {
  content: '\ea7d'; }

/* '' */
.uil-keyboard-alt:before {
  content: '\ea7e'; }

/* '' */
.uil-keyboard-hide:before {
  content: '\ea7f'; }

/* '' */
.uil-keyboard-show:before {
  content: '\ea80'; }

/* '' */
.uil-keyboard:before {
  content: '\ea81'; }

/* '' */
.uil-keyhole-circle:before {
  content: '\ea82'; }

/* '' */
.uil-keyhole-square-full:before {
  content: '\ea83'; }

/* '' */
.uil-keyhole-square:before {
  content: '\ea84'; }

/* '' */
.uil-kid:before {
  content: '\ea85'; }

/* '' */
.uil-label-alt:before {
  content: '\ea86'; }

/* '' */
.uil-label:before {
  content: '\ea87'; }

/* '' */
.uil-lamp:before {
  content: '\ea88'; }

/* '' */
.uil-laptop-cloud:before {
  content: '\ea89'; }

/* '' */
.uil-laptop:before {
  content: '\ea8a'; }

/* '' */
.uil-laughing:before {
  content: '\ea8b'; }

/* '' */
.uil-layer-group-slash:before {
  content: '\ea8c'; }

/* '' */
.uil-layer-group:before {
  content: '\ea8d'; }

/* '' */
.uil-layers-alt:before {
  content: '\ea8e'; }

/* '' */
.uil-layers-slash:before {
  content: '\ea8f'; }

/* '' */
.uil-layers:before {
  content: '\ea90'; }

/* '' */
.uil-left-arrow-from-left:before {
  content: '\ea91'; }

/* '' */
.uil-left-arrow-to-left:before {
  content: '\ea92'; }

/* '' */
.uil-left-indent-alt:before {
  content: '\ea93'; }

/* '' */
.uil-left-indent:before {
  content: '\ea94'; }

/* '' */
.uil-left-to-right-text-direction:before {
  content: '\ea95'; }

/* '' */
.uil-life-ring:before {
  content: '\ea96'; }

/* '' */
.uil-lightbulb-alt:before {
  content: '\ea97'; }

/* '' */
.uil-lightbulb:before {
  content: '\ea98'; }

/* '' */
.uil-line-alt:before {
  content: '\ea99'; }

/* '' */
.uil-line-spacing:before {
  content: '\ea9a'; }

/* '' */
.uil-line:before {
  content: '\ea9b'; }

/* '' */
.uil-link-alt:before {
  content: '\ea9c'; }

/* '' */
.uil-link-broken:before {
  content: '\ea9d'; }

/* '' */
.uil-link-h:before {
  content: '\ea9e'; }

/* '' */
.uil-link:before {
  content: '\ea9f'; }

/* '' */
.uil-linkedin-alt:before {
  content: '\eaa0'; }

/* '' */
.uil-linkedin:before {
  content: '\eaa1'; }

/* '' */
.uil-list-ui-alt:before {
  content: '\eaa2'; }

/* '' */
.uil-list-ul:before {
  content: '\eaa3'; }

/* '' */
.uil-location-arrow-alt:before {
  content: '\eaa4'; }

/* '' */
.uil-location-arrow:before {
  content: '\eaa5'; }

/* '' */
.uil-location-pin-alt:before {
  content: '\eaa6'; }

/* '' */
.uil-location-point:before {
  content: '\eaa7'; }

/* '' */
.uil-location:before {
  content: '\eaa8'; }

/* '' */
.uil-lock-access:before {
  content: '\eaa9'; }

/* '' */
.uil-lock-alt:before {
  content: '\eaaa'; }

/* '' */
.uil-lock-open-alt:before {
  content: '\eaab'; }

/* '' */
.uil-lock-slash:before {
  content: '\eaac'; }

/* '' */
.uil-lock:before {
  content: '\eaad'; }

/* '' */
.uil-mailbox-alt:before {
  content: '\eaae'; }

/* '' */
.uil-mailbox:before {
  content: '\eaaf'; }

/* '' */
.uil-map-marker-alt:before {
  content: '\eab0'; }

/* '' */
.uil-map-marker-edit:before {
  content: '\eab1'; }

/* '' */
.uil-map-marker-info:before {
  content: '\eab2'; }

/* '' */
.uil-map-marker-minus:before {
  content: '\eab3'; }

/* '' */
.uil-map-marker-plus:before {
  content: '\eab4'; }

/* '' */
.uil-map-marker-question:before {
  content: '\eab5'; }

/* '' */
.uil-map-marker-shield:before {
  content: '\eab6'; }

/* '' */
.uil-map-marker-slash:before {
  content: '\eab7'; }

/* '' */
.uil-map-marker:before {
  content: '\eab8'; }

/* '' */
.uil-map-pin-alt:before {
  content: '\eab9'; }

/* '' */
.uil-map-pin:before {
  content: '\eaba'; }

/* '' */
.uil-map:before {
  content: '\eabb'; }

/* '' */
.uil-mars:before {
  content: '\eabc'; }

/* '' */
.uil-master-card:before {
  content: '\eabd'; }

/* '' */
.uil-maximize-left:before {
  content: '\eabe'; }

/* '' */
.uil-medal:before {
  content: '\eabf'; }

/* '' */
.uil-medical-drip:before {
  content: '\eac0'; }

/* '' */
.uil-medical-square-full:before {
  content: '\eac1'; }

/* '' */
.uil-medical-square:before {
  content: '\eac2'; }

/* '' */
.uil-medical:before {
  content: '\eac3'; }

/* '' */
.uil-medium-m:before {
  content: '\eac4'; }

/* '' */
.uil-medkit:before {
  content: '\eac5'; }

/* '' */
.uil-meeting-board:before {
  content: '\eac6'; }

/* '' */
.uil-megaphone:before {
  content: '\eac7'; }

/* '' */
.uil-meh-alt:before {
  content: '\eac8'; }

/* '' */
.uil-meh-closed-eye:before {
  content: '\eac9'; }

/* '' */
.uil-meh:before {
  content: '\eaca'; }

/* '' */
.uil-message:before {
  content: '\eacb'; }

/* '' */
.uil-metro:before {
  content: '\eacc'; }

/* '' */
.uil-microphone-slash:before {
  content: '\eacd'; }

/* '' */
.uil-microphone:before {
  content: '\eace'; }

/* '' */
.uil-minus-circle:before {
  content: '\eacf'; }

/* '' */
.uil-minus-path:before {
  content: '\ead0'; }

/* '' */
.uil-minus-square-full:before {
  content: '\ead1'; }

/* '' */
.uil-minus-square:before {
  content: '\ead2'; }

/* '' */
.uil-minus:before {
  content: '\ead3'; }

/* '' */
.uil-missed-call:before {
  content: '\ead4'; }

/* '' */
.uil-mobey-bill-slash:before {
  content: '\ead5'; }

/* '' */
.uil-mobile-android-alt:before {
  content: '\ead6'; }

/* '' */
.uil-mobile-android:before {
  content: '\ead7'; }

/* '' */
.uil-mobile-vibrate:before {
  content: '\ead8'; }

/* '' */
.uil-modem:before {
  content: '\ead9'; }

/* '' */
.uil-money-bill-stack:before {
  content: '\eada'; }

/* '' */
.uil-money-bill:before {
  content: '\eadb'; }

/* '' */
.uil-money-insert:before {
  content: '\eadc'; }

/* '' */
.uil-money-stack:before {
  content: '\eadd'; }

/* '' */
.uil-money-withdraw:before {
  content: '\eade'; }

/* '' */
.uil-money-withdrawal:before {
  content: '\eadf'; }

/* '' */
.uil-moneybag-alt:before {
  content: '\eae0'; }

/* '' */
.uil-moneybag:before {
  content: '\eae1'; }

/* '' */
.uil-monitor-heart-rate:before {
  content: '\eae2'; }

/* '' */
.uil-monitor:before {
  content: '\eae3'; }

/* '' */
.uil-moon-eclipse:before {
  content: '\eae4'; }

/* '' */
.uil-moon:before {
  content: '\eae5'; }

/* '' */
.uil-moonset:before {
  content: '\eae6'; }

/* '' */
.uil-mountains-sun:before {
  content: '\eae7'; }

/* '' */
.uil-mountains:before {
  content: '\eae8'; }

/* '' */
.uil-mouse-alt:before {
  content: '\eae9'; }

/* '' */
.uil-mouse:before {
  content: '\eaea'; }

/* '' */
.uil-multiply:before {
  content: '\eaeb'; }

/* '' */
.uil-music-note:before {
  content: '\eaec'; }

/* '' */
.uil-music-tune-slash:before {
  content: '\eaed'; }

/* '' */
.uil-music:before {
  content: '\eaee'; }

/* '' */
.uil-n-a:before {
  content: '\eaef'; }

/* '' */
.uil-navigator:before {
  content: '\eaf0'; }

/* '' */
.uil-nerd:before {
  content: '\eaf1'; }

/* '' */
.uil-newspaper:before {
  content: '\eaf2'; }

/* '' */
.uil-ninja:before {
  content: '\eaf3'; }

/* '' */
.uil-no-entry:before {
  content: '\eaf4'; }

/* '' */
.uil-notebooks:before {
  content: '\eaf5'; }

/* '' */
.uil-notes:before {
  content: '\eaf6'; }

/* '' */
.uil-object-group:before {
  content: '\eaf7'; }

/* '' */
.uil-object-ungroup:before {
  content: '\eaf8'; }

/* '' */
.uil-octagon:before {
  content: '\eaf9'; }

/* '' */
.uil-opera-alt:before {
  content: '\eafa'; }

/* '' */
.uil-opera:before {
  content: '\eafb'; }

/* '' */
.uil-outgoing-call:before {
  content: '\eafc'; }

/* '' */
.uil-package:before {
  content: '\eafd'; }

/* '' */
.uil-padlock:before {
  content: '\eafe'; }

/* '' */
.uil-paint-tool:before {
  content: '\eaff'; }

/* '' */
.uil-palette:before {
  content: '\eb00'; }

/* '' */
.uil-panorama-h-alt:before {
  content: '\eb01'; }

/* '' */
.uil-panorama-h:before {
  content: '\eb02'; }

/* '' */
.uil-panorama-v:before {
  content: '\eb03'; }

/* '' */
.uil-paperclip:before {
  content: '\eb04'; }

/* '' */
.uil-paragraph:before {
  content: '\eb05'; }

/* '' */
.uil-parcel:before {
  content: '\eb06'; }

/* '' */
.uil-parking-square:before {
  content: '\eb07'; }

/* '' */
.uil-pathfinder-unite:before {
  content: '\eb08'; }

/* '' */
.uil-pathfinder:before {
  content: '\eb09'; }

/* '' */
.uil-pause-circle:before {
  content: '\eb0a'; }

/* '' */
.uil-pause:before {
  content: '\eb0b'; }

/* '' */
.uil-paypal:before {
  content: '\eb0c'; }

/* '' */
.uil-pen:before {
  content: '\eb0d'; }

/* '' */
.uil-pentagon:before {
  content: '\eb0e'; }

/* '' */
.uil-percentage:before {
  content: '\eb0f'; }

/* '' */
.uil-phone-alt:before {
  content: '\eb10'; }

/* '' */
.uil-phone-pause:before {
  content: '\eb11'; }

/* '' */
.uil-phone-slash:before {
  content: '\eb12'; }

/* '' */
.uil-phone-times:before {
  content: '\eb13'; }

/* '' */
.uil-phone-volume:before {
  content: '\eb14'; }

/* '' */
.uil-phone:before {
  content: '\eb15'; }

/* '' */
.uil-picture:before {
  content: '\eb16'; }

/* '' */
.uil-plane-arrival:before {
  content: '\eb17'; }

/* '' */
.uil-plane-departure:before {
  content: '\eb18'; }

/* '' */
.uil-plane-fly:before {
  content: '\eb19'; }

/* '' */
.uil-plane:before {
  content: '\eb1a'; }

/* '' */
.uil-play-circle:before {
  content: '\eb1b'; }

/* '' */
.uil-play:before {
  content: '\eb1c'; }

/* '' */
.uil-plug:before {
  content: '\eb1d'; }

/* '' */
.uil-plus-circle:before {
  content: '\eb1e'; }

/* '' */
.uil-plus-square:before {
  content: '\eb1f'; }

/* '' */
.uil-plus:before {
  content: '\eb20'; }

/* '' */
.uil-podium:before {
  content: '\eb21'; }

/* '' */
.uil-polygon:before {
  content: '\eb22'; }

/* '' */
.uil-post-stamp:before {
  content: '\eb23'; }

/* '' */
.uil-postcard:before {
  content: '\eb24'; }

/* '' */
.uil-pound-circle:before {
  content: '\eb25'; }

/* '' */
.uil-pound:before {
  content: '\eb26'; }

/* '' */
.uil-power:before {
  content: '\eb27'; }

/* '' */
.uil-prescription-bottle:before {
  content: '\eb28'; }

/* '' */
.uil-presentation-check:before {
  content: '\eb29'; }

/* '' */
.uil-presentation-edit:before {
  content: '\eb2a'; }

/* '' */
.uil-presentation-line:before {
  content: '\eb2b'; }

/* '' */
.uil-presentation-lines-alt:before {
  content: '\eb2c'; }

/* '' */
.uil-presentation-minus:before {
  content: '\eb2d'; }

/* '' */
.uil-presentation-play:before {
  content: '\eb2e'; }

/* '' */
.uil-presentation-plus:before {
  content: '\eb2f'; }

/* '' */
.uil-presentation-times:before {
  content: '\eb30'; }

/* '' */
.uil-presentation:before {
  content: '\eb31'; }

/* '' */
.uil-previous:before {
  content: '\eb32'; }

/* '' */
.uil-pricetag-alt:before {
  content: '\eb33'; }

/* '' */
.uil-print-slash:before {
  content: '\eb34'; }

/* '' */
.uil-print:before {
  content: '\eb35'; }

/* '' */
.uil-process:before {
  content: '\eb36'; }

/* '' */
.uil-processor:before {
  content: '\eb37'; }

/* '' */
.uil-pump:before {
  content: '\eb38'; }

/* '' */
.uil-puzzle-piece:before {
  content: '\eb39'; }

/* '' */
.uil-question-circle:before {
  content: '\eb3a'; }

/* '' */
.uil-raddit-alien-alt:before {
  content: '\eb3b'; }

/* '' */
.uil-rainbow:before {
  content: '\eb3c'; }

/* '' */
.uil-raindrops-alt:before {
  content: '\eb3d'; }

/* '' */
.uil-raindrops:before {
  content: '\eb3e'; }

/* '' */
.uil-react:before {
  content: '\eb3f'; }

/* '' */
.uil-receipt-alt:before {
  content: '\eb40'; }

/* '' */
.uil-receipt:before {
  content: '\eb41'; }

/* '' */
.uil-record-audio:before {
  content: '\eb42'; }

/* '' */
.uil-reddit-alien-alt:before {
  content: '\eb43'; }

/* '' */
.uil-redo:before {
  content: '\eb44'; }

/* '' */
.uil-refresh:before {
  content: '\eb45'; }

/* '' */
.uil-registered:before {
  content: '\eb46'; }

/* '' */
.uil-repeat:before {
  content: '\eb47'; }

/* '' */
.uil-restaurant:before {
  content: '\eb48'; }

/* '' */
.uil-right-indent-alt:before {
  content: '\eb49'; }

/* '' */
.uil-right-to-left-text-direction:before {
  content: '\eb4a'; }

/* '' */
.uil-robot:before {
  content: '\eb4b'; }

/* '' */
.uil-rope-way:before {
  content: '\eb4c'; }

/* '' */
.uil-rotate-360:before {
  content: '\eb4d'; }

/* '' */
.uil-rss-alt:before {
  content: '\eb4e'; }

/* '' */
.uil-rss-interface:before {
  content: '\eb4f'; }

/* '' */
.uil-rss:before {
  content: '\eb50'; }

/* '' */
.uil-ruler-combined:before {
  content: '\eb51'; }

/* '' */
.uil-ruler:before {
  content: '\eb52'; }

/* '' */
.uil-sad-cry:before {
  content: '\eb53'; }

/* '' */
.uil-sad-crying:before {
  content: '\eb54'; }

/* '' */
.uil-sad-dizzy:before {
  content: '\eb55'; }

/* '' */
.uil-sad-squint:before {
  content: '\eb56'; }

/* '' */
.uil-sad:before {
  content: '\eb57'; }

/* '' */
.uil-scaling-left:before {
  content: '\eb58'; }

/* '' */
.uil-scaling-right:before {
  content: '\eb59'; }

/* '' */
.uil-scenery:before {
  content: '\eb5a'; }

/* '' */
.uil-schedule:before {
  content: '\eb5b'; }

/* '' */
.uil-science:before {
  content: '\eb5c'; }

/* '' */
.uil-screw:before {
  content: '\eb5d'; }

/* '' */
.uil-scroll-h:before {
  content: '\eb5e'; }

/* '' */
.uil-scroll:before {
  content: '\eb5f'; }

/* '' */
.uil-search-alt:before {
  content: '\eb60'; }

/* '' */
.uil-search-minus:before {
  content: '\eb61'; }

/* '' */
.uil-search-plus:before {
  content: '\eb62'; }

/* '' */
.uil-search:before {
  content: '\eb63'; }

/* '' */
.uil-selfie:before {
  content: '\eb64'; }

/* '' */
.uil-server-alt:before {
  content: '\eb65'; }

/* '' */
.uil-server-connection:before {
  content: '\eb66'; }

/* '' */
.uil-server-network-alt:before {
  content: '\eb67'; }

/* '' */
.uil-server-network:before {
  content: '\eb68'; }

/* '' */
.uil-server:before {
  content: '\eb69'; }

/* '' */
.uil-servers:before {
  content: '\eb6a'; }

/* '' */
.uil-servicemark:before {
  content: '\eb6b'; }

/* '' */
.uil-share-alt:before {
  content: '\eb6c'; }

/* '' */
.uil-shield-check:before {
  content: '\eb6d'; }

/* '' */
.uil-shield-exclamation:before {
  content: '\eb6e'; }

/* '' */
.uil-shield-question:before {
  content: '\eb6f'; }

/* '' */
.uil-shield-slash:before {
  content: '\eb70'; }

/* '' */
.uil-shield:before {
  content: '\eb71'; }

/* '' */
.uil-ship:before {
  content: '\eb72'; }

/* '' */
.uil-shop:before {
  content: '\eb73'; }

/* '' */
.uil-shopping-basket:before {
  content: '\eb74'; }

/* '' */
.uil-shopping-cart-alt:before {
  content: '\eb75'; }

/* '' */
.uil-shopping-trolley:before {
  content: '\eb76'; }

/* '' */
.uil-shovel:before {
  content: '\eb77'; }

/* '' */
.uil-shrink:before {
  content: '\eb78'; }

/* '' */
.uil-shuffle:before {
  content: '\eb79'; }

/* '' */
.uil-shutter-alt:before {
  content: '\eb7a'; }

/* '' */
.uil-shutter:before {
  content: '\eb7b'; }

/* '' */
.uil-sick:before {
  content: '\eb7c'; }

/* '' */
.uil-sigma:before {
  content: '\eb7d'; }

/* '' */
.uil-sign-alt:before {
  content: '\eb7e'; }

/* '' */
.uil-sign-in-alt:before {
  content: '\eb7f'; }

/* '' */
.uil-sign-left:before {
  content: '\eb80'; }

/* '' */
.uil-sign-out-alt:before {
  content: '\eb81'; }

/* '' */
.uil-sign-right:before {
  content: '\eb82'; }

/* '' */
.uil-signal-alt-3:before {
  content: '\eb83'; }

/* '' */
.uil-signal-alt:before {
  content: '\eb84'; }

/* '' */
.uil-signal:before {
  content: '\eb85'; }

/* '' */
.uil-silence:before {
  content: '\eb86'; }

/* '' */
.uil-silent-squint:before {
  content: '\eb87'; }

/* '' */
.uil-sim-card:before {
  content: '\eb88'; }

/* '' */
.uil-sitemap:before {
  content: '\eb89'; }

/* '' */
.uil-skip-forward-alt:before {
  content: '\eb8a'; }

/* '' */
.uil-skip-forward-circle:before {
  content: '\eb8b'; }

/* '' */
.uil-skip-forward:before {
  content: '\eb8c'; }

/* '' */
.uil-skype-alt:before {
  content: '\eb8d'; }

/* '' */
.uil-skype:before {
  content: '\eb8e'; }

/* '' */
.uil-slack-alt:before {
  content: '\eb8f'; }

/* '' */
.uil-slack:before {
  content: '\eb90'; }

/* '' */
.uil-sliders-v-alt:before {
  content: '\eb91'; }

/* '' */
.uil-sliders-v:before {
  content: '\eb92'; }

/* '' */
.uil-smile-beam:before {
  content: '\eb93'; }

/* '' */
.uil-smile-dizzy:before {
  content: '\eb94'; }

/* '' */
.uil-smile-squint-wink-alt:before {
  content: '\eb95'; }

/* '' */
.uil-smile-squint-wink:before {
  content: '\eb96'; }

/* '' */
.uil-smile-wink-alt:before {
  content: '\eb97'; }

/* '' */
.uil-smile-wink:before {
  content: '\eb98'; }

/* '' */
.uil-smile:before {
  content: '\eb99'; }

/* '' */
.uil-snapchat-alt:before {
  content: '\eb9a'; }

/* '' */
.uil-snapchat-ghost:before {
  content: '\eb9b'; }

/* '' */
.uil-snapchat-square:before {
  content: '\eb9c'; }

/* '' */
.uil-snow-flake:before {
  content: '\eb9d'; }

/* '' */
.uil-snowflake-alt:before {
  content: '\eb9e'; }

/* '' */
.uil-snowflake:before {
  content: '\eb9f'; }

/* '' */
.uil-sort-amount-down:before {
  content: '\eba0'; }

/* '' */
.uil-sort-amount-up:before {
  content: '\eba1'; }

/* '' */
.uil-sort:before {
  content: '\eba2'; }

/* '' */
.uil-sorting:before {
  content: '\eba3'; }

/* '' */
.uil-space-key:before {
  content: '\eba4'; }

/* '' */
.uil-spade:before {
  content: '\eba5'; }

/* '' */
.uil-sperms:before {
  content: '\eba6'; }

/* '' */
.uil-spin:before {
  content: '\eba7'; }

/* '' */
.uil-sport:before {
  content: '\eba8'; }

/* '' */
.uil-square-full:before {
  content: '\eba9'; }

/* '' */
.uil-square-shape:before {
  content: '\ebaa'; }

/* '' */
.uil-square:before {
  content: '\ebab'; }

/* '' */
.uil-squint:before {
  content: '\ebac'; }

/* '' */
.uil-star-half-alt:before {
  content: '\ebad'; }

/* '' */
.uil-star:before {
  content: '\ebae'; }

/* '' */
.uil-step-backward-alt:before {
  content: '\ebaf'; }

/* '' */
.uil-step-backward-circle:before {
  content: '\ebb0'; }

/* '' */
.uil-step-backward:before {
  content: '\ebb1'; }

/* '' */
.uil-step-forward:before {
  content: '\ebb2'; }

/* '' */
.uil-stop-circle:before {
  content: '\ebb3'; }

/* '' */
.uil-stopwatch-slash:before {
  content: '\ebb4'; }

/* '' */
.uil-stopwatch:before {
  content: '\ebb5'; }

/* '' */
.uil-store-alt:before {
  content: '\ebb6'; }

/* '' */
.uil-store:before {
  content: '\ebb7'; }

/* '' */
.uil-streering:before {
  content: '\ebb8'; }

/* '' */
.uil-stretcher:before {
  content: '\ebb9'; }

/* '' */
.uil-subject:before {
  content: '\ebba'; }

/* '' */
.uil-subway-alt:before {
  content: '\ebbb'; }

/* '' */
.uil-subway:before {
  content: '\ebbc'; }

/* '' */
.uil-suitcase-alt:before {
  content: '\ebbd'; }

/* '' */
.uil-suitcase:before {
  content: '\ebbe'; }

/* '' */
.uil-sun:before {
  content: '\ebbf'; }

/* '' */
.uil-sunset:before {
  content: '\ebc0'; }

/* '' */
.uil-surprise:before {
  content: '\ebc1'; }

/* '' */
.uil-swatchbook:before {
  content: '\ebc2'; }

/* '' */
.uil-swiggy:before {
  content: '\ebc3'; }

/* '' */
.uil-swimmer:before {
  content: '\ebc4'; }

/* '' */
.uil-symbol:before {
  content: '\ebc5'; }

/* '' */
.uil-sync-exclamation:before {
  content: '\ebc6'; }

/* '' */
.uil-sync-slash:before {
  content: '\ebc7'; }

/* '' */
.uil-sync:before {
  content: '\ebc8'; }

/* '' */
.uil-syringe:before {
  content: '\ebc9'; }

/* '' */
.uil-table:before {
  content: '\ebca'; }

/* '' */
.uil-tablet:before {
  content: '\ebcb'; }

/* '' */
.uil-tablets:before {
  content: '\ebcc'; }

/* '' */
.uil-tachometer-fast:before {
  content: '\ebcd'; }

/* '' */
.uil-tag-alt:before {
  content: '\ebce'; }

/* '' */
.uil-tag:before {
  content: '\ebcf'; }

/* '' */
.uil-tape:before {
  content: '\ebd0'; }

/* '' */
.uil-taxi:before {
  content: '\ebd1'; }

/* '' */
.uil-tear:before {
  content: '\ebd2'; }

/* '' */
.uil-technology:before {
  content: '\ebd3'; }

/* '' */
.uil-telegram-alt:before {
  content: '\ebd4'; }

/* '' */
.uil-telegram:before {
  content: '\ebd5'; }

/* '' */
.uil-telescope:before {
  content: '\ebd6'; }

/* '' */
.uil-temperature-empty:before {
  content: '\ebd7'; }

/* '' */
.uil-temperature-half:before {
  content: '\ebd8'; }

/* '' */
.uil-temperature-minus:before {
  content: '\ebd9'; }

/* '' */
.uil-temperature-plus:before {
  content: '\ebda'; }

/* '' */
.uil-temperature-quarter:before {
  content: '\ebdb'; }

/* '' */
.uil-temperature-three-quarter:before {
  content: '\ebdc'; }

/* '' */
.uil-temperature:before {
  content: '\ebdd'; }

/* '' */
.uil-text-fields:before {
  content: '\ebde'; }

/* '' */
.uil-text-size:before {
  content: '\ebdf'; }

/* '' */
.uil-text-strike-through:before {
  content: '\ebe0'; }

/* '' */
.uil-text:before {
  content: '\ebe1'; }

/* '' */
.uil-th-large:before {
  content: '\ebe2'; }

/* '' */
.uil-th-slash:before {
  content: '\ebe3'; }

/* '' */
.uil-th:before {
  content: '\ebe4'; }

/* '' */
.uil-thermometer:before {
  content: '\ebe5'; }

/* '' */
.uil-thumbs-down:before {
  content: '\ebe6'; }

/* '' */
.uil-thumbs-up:before {
  content: '\ebe7'; }

/* '' */
.uil-thunderstorm-moon:before {
  content: '\ebe8'; }

/* '' */
.uil-thunderstorm-sun:before {
  content: '\ebe9'; }

/* '' */
.uil-thunderstorm:before {
  content: '\ebea'; }

/* '' */
.uil-ticket:before {
  content: '\ebeb'; }

/* '' */
.uil-times-circle:before {
  content: '\ebec'; }

/* '' */
.uil-times-square:before {
  content: '\ebed'; }

/* '' */
.uil-times:before {
  content: '\ebee'; }

/* '' */
.uil-toggle-off:before {
  content: '\ebef'; }

/* '' */
.uil-toggle-on:before {
  content: '\ebf0'; }

/* '' */
.uil-top-arrow-from-top:before {
  content: '\ebf1'; }

/* '' */
.uil-top-arrow-to-top:before {
  content: '\ebf2'; }

/* '' */
.uil-tornado:before {
  content: '\ebf3'; }

/* '' */
.uil-trademark-circle:before {
  content: '\ebf4'; }

/* '' */
.uil-trademark:before {
  content: '\ebf5'; }

/* '' */
.uil-traffic-barrier:before {
  content: '\ebf6'; }

/* '' */
.uil-trash-alt:before {
  content: '\ebf7'; }

/* '' */
.uil-trash:before {
  content: '\ebf8'; }

/* '' */
.uil-trees:before {
  content: '\ebf9'; }

/* '' */
.uil-triangle:before {
  content: '\ebfa'; }

/* '' */
.uil-trophy:before {
  content: '\ebfb'; }

/* '' */
.uil-trowel:before {
  content: '\ebfc'; }

/* '' */
.uil-truck-case:before {
  content: '\ebfd'; }

/* '' */
.uil-truck-loading:before {
  content: '\ebfe'; }

/* '' */
.uil-truck:before {
  content: '\ebff'; }

/* '' */
.uil-tumblr-alt:before {
  content: '\ec00'; }

/* '' */
.uil-tumblr-square:before {
  content: '\ec01'; }

/* '' */
.uil-tumblr:before {
  content: '\ec02'; }

/* '' */
.uil-tv-retro-slash:before {
  content: '\ec03'; }

/* '' */
.uil-tv-retro:before {
  content: '\ec04'; }

/* '' */
.uil-twitter-alt:before {
  content: '\ec05'; }

/* '' */
.uil-twitter:before {
  content: '\ec06'; }

/* '' */
.uil-umbrella:before {
  content: '\ec07'; }

/* '' */
.uil-unamused:before {
  content: '\ec08'; }

/* '' */
.uil-underline:before {
  content: '\ec09'; }

/* '' */
.uil-university:before {
  content: '\ec0a'; }

/* '' */
.uil-unlock-alt:before {
  content: '\ec0b'; }

/* '' */
.uil-unlock:before {
  content: '\ec0c'; }

/* '' */
.uil-upload-alt:before {
  content: '\ec0d'; }

/* '' */
.uil-upload:before {
  content: '\ec0e'; }

/* '' */
.uil-usd-circle:before {
  content: '\ec0f'; }

/* '' */
.uil-usd-square:before {
  content: '\ec10'; }

/* '' */
.uil-user-check:before {
  content: '\ec11'; }

/* '' */
.uil-user-circle:before {
  content: '\ec12'; }

/* '' */
.uil-user-exclamation:before {
  content: '\ec13'; }

/* '' */
.uil-user-hard-hat:before {
  content: '\ec14'; }

/* '' */
.uil-user-minus:before {
  content: '\ec15'; }

/* '' */
.uil-user-plus:before {
  content: '\ec16'; }

/* '' */
.uil-user-square:before {
  content: '\ec17'; }

/* '' */
.uil-user-times:before {
  content: '\ec18'; }

/* '' */
.uil-user:before {
  content: '\ec19'; }

/* '' */
.uil-users-alt:before {
  content: '\ec1a'; }

/* '' */
.uil-utensils-alt:before {
  content: '\ec1b'; }

/* '' */
.uil-utensils:before {
  content: '\ec1c'; }

/* '' */
.uil-vector-square-alt:before {
  content: '\ec1d'; }

/* '' */
.uil-vector-square:before {
  content: '\ec1e'; }

/* '' */
.uil-venus:before {
  content: '\ec1f'; }

/* '' */
.uil-vertical-align-bottom:before {
  content: '\ec20'; }

/* '' */
.uil-vertical-align-center:before {
  content: '\ec21'; }

/* '' */
.uil-vertical-align-top:before {
  content: '\ec22'; }

/* '' */
.uil-vertical-distribute-bottom:before {
  content: '\ec23'; }

/* '' */
.uil-vertical-distribution-center:before {
  content: '\ec24'; }

/* '' */
.uil-vertical-distribution-top:before {
  content: '\ec25'; }

/* '' */
.uil-video-slash:before {
  content: '\ec26'; }

/* '' */
.uil-video:before {
  content: '\ec27'; }

/* '' */
.uil-visual-studio:before {
  content: '\ec28'; }

/* '' */
.uil-vk-alt:before {
  content: '\ec29'; }

/* '' */
.uil-vk:before {
  content: '\ec2a'; }

/* '' */
.uil-voicemail-rectangle:before {
  content: '\ec2b'; }

/* '' */
.uil-voicemail:before {
  content: '\ec2c'; }

/* '' */
.uil-volleyball:before {
  content: '\ec2d'; }

/* '' */
.uil-volume-down:before {
  content: '\ec2e'; }

/* '' */
.uil-volume-mute:before {
  content: '\ec2f'; }

/* '' */
.uil-volume-off:before {
  content: '\ec30'; }

/* '' */
.uil-volume-up:before {
  content: '\ec31'; }

/* '' */
.uil-volume:before {
  content: '\ec32'; }

/* '' */
.uil-vuejs-alt:before {
  content: '\ec33'; }

/* '' */
.uil-vuejs:before {
  content: '\ec34'; }

/* '' */
.uil-wall:before {
  content: '\ec35'; }

/* '' */
.uil-wallet:before {
  content: '\ec36'; }

/* '' */
.uil-watch-alt:before {
  content: '\ec37'; }

/* '' */
.uil-watch:before {
  content: '\ec38'; }

/* '' */
.uil-water-drop-slash:before {
  content: '\ec39'; }

/* '' */
.uil-water-glass:before {
  content: '\ec3a'; }

/* '' */
.uil-water:before {
  content: '\ec3b'; }

/* '' */
.uil-web-grid-alt:before {
  content: '\ec3c'; }

/* '' */
.uil-web-grid:before {
  content: '\ec3d'; }

/* '' */
.uil-web-section-alt:before {
  content: '\ec3e'; }

/* '' */
.uil-web-section:before {
  content: '\ec3f'; }

/* '' */
.uil-webcam:before {
  content: '\ec40'; }

/* '' */
.uil-weight:before {
  content: '\ec41'; }

/* '' */
.uil-whatsapp:before {
  content: '\ec42'; }

/* '' */
.uil-wheel-barrow:before {
  content: '\ec43'; }

/* '' */
.uil-wheelchair-alt:before {
  content: '\ec44'; }

/* '' */
.uil-wheelchair:before {
  content: '\ec45'; }

/* '' */
.uil-wifi-router:before {
  content: '\ec46'; }

/* '' */
.uil-wifi-slash:before {
  content: '\ec47'; }

/* '' */
.uil-wifi:before {
  content: '\ec48'; }

/* '' */
.uil-wind-moon:before {
  content: '\ec49'; }

/* '' */
.uil-wind-sun:before {
  content: '\ec4a'; }

/* '' */
.uil-wind:before {
  content: '\ec4b'; }

/* '' */
.uil-window-grid:before {
  content: '\ec4c'; }

/* '' */
.uil-window-maximize:before {
  content: '\ec4d'; }

/* '' */
.uil-window-restore:before {
  content: '\ec4e'; }

/* '' */
.uil-window-section:before {
  content: '\ec4f'; }

/* '' */
.uil-window:before {
  content: '\ec50'; }

/* '' */
.uil-windsock:before {
  content: '\ec51'; }

/* '' */
.uil-wrap-text:before {
  content: '\ec52'; }

/* '' */
.uil-wrench:before {
  content: '\ec53'; }

/* '' */
.uil-yellow:before {
  content: '\ec54'; }

/* '' */
.uil-yen-circle:before {
  content: '\ec55'; }

/* '' */
.uil-yen:before {
  content: '\ec56'; }

/* '' */
.uil-yin-yang:before {
  content: '\ec57'; }

/* '' */
.uil-youtube-alt:before {
  content: '\ec58'; }

/* '' */
.uil-youtube:before {
  content: '\ec59'; }

/* '' */
@font-face {
  font-family: "bootstrap-icons";
  src: url("../fonts/bootstrap-icons.woff2?856008caa5eb66df68595e734e59580d") format("woff2"), url("../fonts/bootstrap-icons.woff?856008caa5eb66df68595e734e59580d") format("woff"); }

[class^="bi-"]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.bi-alarm-fill::before {
  content: "\f101"; }

.bi-alarm::before {
  content: "\f102"; }

.bi-align-bottom::before {
  content: "\f103"; }

.bi-align-center::before {
  content: "\f104"; }

.bi-align-end::before {
  content: "\f105"; }

.bi-align-middle::before {
  content: "\f106"; }

.bi-align-start::before {
  content: "\f107"; }

.bi-align-top::before {
  content: "\f108"; }

.bi-alt::before {
  content: "\f109"; }

.bi-app-indicator::before {
  content: "\f10a"; }

.bi-app::before {
  content: "\f10b"; }

.bi-archive-fill::before {
  content: "\f10c"; }

.bi-archive::before {
  content: "\f10d"; }

.bi-arrow-90deg-down::before {
  content: "\f10e"; }

.bi-arrow-90deg-left::before {
  content: "\f10f"; }

.bi-arrow-90deg-right::before {
  content: "\f110"; }

.bi-arrow-90deg-up::before {
  content: "\f111"; }

.bi-arrow-bar-down::before {
  content: "\f112"; }

.bi-arrow-bar-left::before {
  content: "\f113"; }

.bi-arrow-bar-right::before {
  content: "\f114"; }

.bi-arrow-bar-up::before {
  content: "\f115"; }

.bi-arrow-clockwise::before {
  content: "\f116"; }

.bi-arrow-counterclockwise::before {
  content: "\f117"; }

.bi-arrow-down-circle-fill::before {
  content: "\f118"; }

.bi-arrow-down-circle::before {
  content: "\f119"; }

.bi-arrow-down-left-circle-fill::before {
  content: "\f11a"; }

.bi-arrow-down-left-circle::before {
  content: "\f11b"; }

.bi-arrow-down-left-square-fill::before {
  content: "\f11c"; }

.bi-arrow-down-left-square::before {
  content: "\f11d"; }

.bi-arrow-down-left::before {
  content: "\f11e"; }

.bi-arrow-down-right-circle-fill::before {
  content: "\f11f"; }

.bi-arrow-down-right-circle::before {
  content: "\f120"; }

.bi-arrow-down-right-square-fill::before {
  content: "\f121"; }

.bi-arrow-down-right-square::before {
  content: "\f122"; }

.bi-arrow-down-right::before {
  content: "\f123"; }

.bi-arrow-down-short::before {
  content: "\f124"; }

.bi-arrow-down-square-fill::before {
  content: "\f125"; }

.bi-arrow-down-square::before {
  content: "\f126"; }

.bi-arrow-down-up::before {
  content: "\f127"; }

.bi-arrow-down::before {
  content: "\f128"; }

.bi-arrow-left-circle-fill::before {
  content: "\f129"; }

.bi-arrow-left-circle::before {
  content: "\f12a"; }

.bi-arrow-left-right::before {
  content: "\f12b"; }

.bi-arrow-left-short::before {
  content: "\f12c"; }

.bi-arrow-left-square-fill::before {
  content: "\f12d"; }

.bi-arrow-left-square::before {
  content: "\f12e"; }

.bi-arrow-left::before {
  content: "\f12f"; }

.bi-arrow-repeat::before {
  content: "\f130"; }

.bi-arrow-return-left::before {
  content: "\f131"; }

.bi-arrow-return-right::before {
  content: "\f132"; }

.bi-arrow-right-circle-fill::before {
  content: "\f133"; }

.bi-arrow-right-circle::before {
  content: "\f134"; }

.bi-arrow-right-short::before {
  content: "\f135"; }

.bi-arrow-right-square-fill::before {
  content: "\f136"; }

.bi-arrow-right-square::before {
  content: "\f137"; }

.bi-arrow-right::before {
  content: "\f138"; }

.bi-arrow-up-circle-fill::before {
  content: "\f139"; }

.bi-arrow-up-circle::before {
  content: "\f13a"; }

.bi-arrow-up-left-circle-fill::before {
  content: "\f13b"; }

.bi-arrow-up-left-circle::before {
  content: "\f13c"; }

.bi-arrow-up-left-square-fill::before {
  content: "\f13d"; }

.bi-arrow-up-left-square::before {
  content: "\f13e"; }

.bi-arrow-up-left::before {
  content: "\f13f"; }

.bi-arrow-up-right-circle-fill::before {
  content: "\f140"; }

.bi-arrow-up-right-circle::before {
  content: "\f141"; }

.bi-arrow-up-right-square-fill::before {
  content: "\f142"; }

.bi-arrow-up-right-square::before {
  content: "\f143"; }

.bi-arrow-up-right::before {
  content: "\f144"; }

.bi-arrow-up-short::before {
  content: "\f145"; }

.bi-arrow-up-square-fill::before {
  content: "\f146"; }

.bi-arrow-up-square::before {
  content: "\f147"; }

.bi-arrow-up::before {
  content: "\f148"; }

.bi-arrows-angle-contract::before {
  content: "\f149"; }

.bi-arrows-angle-expand::before {
  content: "\f14a"; }

.bi-arrows-collapse::before {
  content: "\f14b"; }

.bi-arrows-expand::before {
  content: "\f14c"; }

.bi-arrows-fullscreen::before {
  content: "\f14d"; }

.bi-arrows-move::before {
  content: "\f14e"; }

.bi-aspect-ratio-fill::before {
  content: "\f14f"; }

.bi-aspect-ratio::before {
  content: "\f150"; }

.bi-asterisk::before {
  content: "\f151"; }

.bi-at::before {
  content: "\f152"; }

.bi-award-fill::before {
  content: "\f153"; }

.bi-award::before {
  content: "\f154"; }

.bi-back::before {
  content: "\f155"; }

.bi-backspace-fill::before {
  content: "\f156"; }

.bi-backspace-reverse-fill::before {
  content: "\f157"; }

.bi-backspace-reverse::before {
  content: "\f158"; }

.bi-backspace::before {
  content: "\f159"; }

.bi-badge-3d-fill::before {
  content: "\f15a"; }

.bi-badge-3d::before {
  content: "\f15b"; }

.bi-badge-4k-fill::before {
  content: "\f15c"; }

.bi-badge-4k::before {
  content: "\f15d"; }

.bi-badge-8k-fill::before {
  content: "\f15e"; }

.bi-badge-8k::before {
  content: "\f15f"; }

.bi-badge-ad-fill::before {
  content: "\f160"; }

.bi-badge-ad::before {
  content: "\f161"; }

.bi-badge-ar-fill::before {
  content: "\f162"; }

.bi-badge-ar::before {
  content: "\f163"; }

.bi-badge-cc-fill::before {
  content: "\f164"; }

.bi-badge-cc::before {
  content: "\f165"; }

.bi-badge-hd-fill::before {
  content: "\f166"; }

.bi-badge-hd::before {
  content: "\f167"; }

.bi-badge-tm-fill::before {
  content: "\f168"; }

.bi-badge-tm::before {
  content: "\f169"; }

.bi-badge-vo-fill::before {
  content: "\f16a"; }

.bi-badge-vo::before {
  content: "\f16b"; }

.bi-badge-vr-fill::before {
  content: "\f16c"; }

.bi-badge-vr::before {
  content: "\f16d"; }

.bi-badge-wc-fill::before {
  content: "\f16e"; }

.bi-badge-wc::before {
  content: "\f16f"; }

.bi-bag-check-fill::before {
  content: "\f170"; }

.bi-bag-check::before {
  content: "\f171"; }

.bi-bag-dash-fill::before {
  content: "\f172"; }

.bi-bag-dash::before {
  content: "\f173"; }

.bi-bag-fill::before {
  content: "\f174"; }

.bi-bag-plus-fill::before {
  content: "\f175"; }

.bi-bag-plus::before {
  content: "\f176"; }

.bi-bag-x-fill::before {
  content: "\f177"; }

.bi-bag-x::before {
  content: "\f178"; }

.bi-bag::before {
  content: "\f179"; }

.bi-bar-chart-fill::before {
  content: "\f17a"; }

.bi-bar-chart-line-fill::before {
  content: "\f17b"; }

.bi-bar-chart-line::before {
  content: "\f17c"; }

.bi-bar-chart-steps::before {
  content: "\f17d"; }

.bi-bar-chart::before {
  content: "\f17e"; }

.bi-basket-fill::before {
  content: "\f17f"; }

.bi-basket::before {
  content: "\f180"; }

.bi-basket2-fill::before {
  content: "\f181"; }

.bi-basket2::before {
  content: "\f182"; }

.bi-basket3-fill::before {
  content: "\f183"; }

.bi-basket3::before {
  content: "\f184"; }

.bi-battery-charging::before {
  content: "\f185"; }

.bi-battery-full::before {
  content: "\f186"; }

.bi-battery-half::before {
  content: "\f187"; }

.bi-battery::before {
  content: "\f188"; }

.bi-bell-fill::before {
  content: "\f189"; }

.bi-bell::before {
  content: "\f18a"; }

.bi-bezier::before {
  content: "\f18b"; }

.bi-bezier2::before {
  content: "\f18c"; }

.bi-bicycle::before {
  content: "\f18d"; }

.bi-binoculars-fill::before {
  content: "\f18e"; }

.bi-binoculars::before {
  content: "\f18f"; }

.bi-blockquote-left::before {
  content: "\f190"; }

.bi-blockquote-right::before {
  content: "\f191"; }

.bi-book-fill::before {
  content: "\f192"; }

.bi-book-half::before {
  content: "\f193"; }

.bi-book::before {
  content: "\f194"; }

.bi-bookmark-check-fill::before {
  content: "\f195"; }

.bi-bookmark-check::before {
  content: "\f196"; }

.bi-bookmark-dash-fill::before {
  content: "\f197"; }

.bi-bookmark-dash::before {
  content: "\f198"; }

.bi-bookmark-fill::before {
  content: "\f199"; }

.bi-bookmark-heart-fill::before {
  content: "\f19a"; }

.bi-bookmark-heart::before {
  content: "\f19b"; }

.bi-bookmark-plus-fill::before {
  content: "\f19c"; }

.bi-bookmark-plus::before {
  content: "\f19d"; }

.bi-bookmark-star-fill::before {
  content: "\f19e"; }

.bi-bookmark-star::before {
  content: "\f19f"; }

.bi-bookmark-x-fill::before {
  content: "\f1a0"; }

.bi-bookmark-x::before {
  content: "\f1a1"; }

.bi-bookmark::before {
  content: "\f1a2"; }

.bi-bookmarks-fill::before {
  content: "\f1a3"; }

.bi-bookmarks::before {
  content: "\f1a4"; }

.bi-bookshelf::before {
  content: "\f1a5"; }

.bi-bootstrap-fill::before {
  content: "\f1a6"; }

.bi-bootstrap-reboot::before {
  content: "\f1a7"; }

.bi-bootstrap::before {
  content: "\f1a8"; }

.bi-border-all::before {
  content: "\f1a9"; }

.bi-border-bottom::before {
  content: "\f1aa"; }

.bi-border-center::before {
  content: "\f1ab"; }

.bi-border-inner::before {
  content: "\f1ac"; }

.bi-border-left::before {
  content: "\f1ad"; }

.bi-border-middle::before {
  content: "\f1ae"; }

.bi-border-outer::before {
  content: "\f1af"; }

.bi-border-right::before {
  content: "\f1b0"; }

.bi-border-style::before {
  content: "\f1b1"; }

.bi-border-top::before {
  content: "\f1b2"; }

.bi-border-width::before {
  content: "\f1b3"; }

.bi-border::before {
  content: "\f1b4"; }

.bi-bounding-box-circles::before {
  content: "\f1b5"; }

.bi-bounding-box::before {
  content: "\f1b6"; }

.bi-box-arrow-down-left::before {
  content: "\f1b7"; }

.bi-box-arrow-down-right::before {
  content: "\f1b8"; }

.bi-box-arrow-down::before {
  content: "\f1b9"; }

.bi-box-arrow-in-down-left::before {
  content: "\f1ba"; }

.bi-box-arrow-in-down-right::before {
  content: "\f1bb"; }

.bi-box-arrow-in-down::before {
  content: "\f1bc"; }

.bi-box-arrow-in-left::before {
  content: "\f1bd"; }

.bi-box-arrow-in-right::before {
  content: "\f1be"; }

.bi-box-arrow-in-up-left::before {
  content: "\f1bf"; }

.bi-box-arrow-in-up-right::before {
  content: "\f1c0"; }

.bi-box-arrow-in-up::before {
  content: "\f1c1"; }

.bi-box-arrow-left::before {
  content: "\f1c2"; }

.bi-box-arrow-right::before {
  content: "\f1c3"; }

.bi-box-arrow-up-left::before {
  content: "\f1c4"; }

.bi-box-arrow-up-right::before {
  content: "\f1c5"; }

.bi-box-arrow-up::before {
  content: "\f1c6"; }

.bi-box-seam::before {
  content: "\f1c7"; }

.bi-box::before {
  content: "\f1c8"; }

.bi-braces::before {
  content: "\f1c9"; }

.bi-bricks::before {
  content: "\f1ca"; }

.bi-briefcase-fill::before {
  content: "\f1cb"; }

.bi-briefcase::before {
  content: "\f1cc"; }

.bi-brightness-alt-high-fill::before {
  content: "\f1cd"; }

.bi-brightness-alt-high::before {
  content: "\f1ce"; }

.bi-brightness-alt-low-fill::before {
  content: "\f1cf"; }

.bi-brightness-alt-low::before {
  content: "\f1d0"; }

.bi-brightness-high-fill::before {
  content: "\f1d1"; }

.bi-brightness-high::before {
  content: "\f1d2"; }

.bi-brightness-low-fill::before {
  content: "\f1d3"; }

.bi-brightness-low::before {
  content: "\f1d4"; }

.bi-broadcast-pin::before {
  content: "\f1d5"; }

.bi-broadcast::before {
  content: "\f1d6"; }

.bi-brush-fill::before {
  content: "\f1d7"; }

.bi-brush::before {
  content: "\f1d8"; }

.bi-bucket-fill::before {
  content: "\f1d9"; }

.bi-bucket::before {
  content: "\f1da"; }

.bi-bug-fill::before {
  content: "\f1db"; }

.bi-bug::before {
  content: "\f1dc"; }

.bi-building::before {
  content: "\f1dd"; }

.bi-bullseye::before {
  content: "\f1de"; }

.bi-calculator-fill::before {
  content: "\f1df"; }

.bi-calculator::before {
  content: "\f1e0"; }

.bi-calendar-check-fill::before {
  content: "\f1e1"; }

.bi-calendar-check::before {
  content: "\f1e2"; }

.bi-calendar-date-fill::before {
  content: "\f1e3"; }

.bi-calendar-date::before {
  content: "\f1e4"; }

.bi-calendar-day-fill::before {
  content: "\f1e5"; }

.bi-calendar-day::before {
  content: "\f1e6"; }

.bi-calendar-event-fill::before {
  content: "\f1e7"; }

.bi-calendar-event::before {
  content: "\f1e8"; }

.bi-calendar-fill::before {
  content: "\f1e9"; }

.bi-calendar-minus-fill::before {
  content: "\f1ea"; }

.bi-calendar-minus::before {
  content: "\f1eb"; }

.bi-calendar-month-fill::before {
  content: "\f1ec"; }

.bi-calendar-month::before {
  content: "\f1ed"; }

.bi-calendar-plus-fill::before {
  content: "\f1ee"; }

.bi-calendar-plus::before {
  content: "\f1ef"; }

.bi-calendar-range-fill::before {
  content: "\f1f0"; }

.bi-calendar-range::before {
  content: "\f1f1"; }

.bi-calendar-week-fill::before {
  content: "\f1f2"; }

.bi-calendar-week::before {
  content: "\f1f3"; }

.bi-calendar-x-fill::before {
  content: "\f1f4"; }

.bi-calendar-x::before {
  content: "\f1f5"; }

.bi-calendar::before {
  content: "\f1f6"; }

.bi-calendar2-check-fill::before {
  content: "\f1f7"; }

.bi-calendar2-check::before {
  content: "\f1f8"; }

.bi-calendar2-date-fill::before {
  content: "\f1f9"; }

.bi-calendar2-date::before {
  content: "\f1fa"; }

.bi-calendar2-day-fill::before {
  content: "\f1fb"; }

.bi-calendar2-day::before {
  content: "\f1fc"; }

.bi-calendar2-event-fill::before {
  content: "\f1fd"; }

.bi-calendar2-event::before {
  content: "\f1fe"; }

.bi-calendar2-fill::before {
  content: "\f1ff"; }

.bi-calendar2-minus-fill::before {
  content: "\f200"; }

.bi-calendar2-minus::before {
  content: "\f201"; }

.bi-calendar2-month-fill::before {
  content: "\f202"; }

.bi-calendar2-month::before {
  content: "\f203"; }

.bi-calendar2-plus-fill::before {
  content: "\f204"; }

.bi-calendar2-plus::before {
  content: "\f205"; }

.bi-calendar2-range-fill::before {
  content: "\f206"; }

.bi-calendar2-range::before {
  content: "\f207"; }

.bi-calendar2-week-fill::before {
  content: "\f208"; }

.bi-calendar2-week::before {
  content: "\f209"; }

.bi-calendar2-x-fill::before {
  content: "\f20a"; }

.bi-calendar2-x::before {
  content: "\f20b"; }

.bi-calendar2::before {
  content: "\f20c"; }

.bi-calendar3-event-fill::before {
  content: "\f20d"; }

.bi-calendar3-event::before {
  content: "\f20e"; }

.bi-calendar3-fill::before {
  content: "\f20f"; }

.bi-calendar3-range-fill::before {
  content: "\f210"; }

.bi-calendar3-range::before {
  content: "\f211"; }

.bi-calendar3-week-fill::before {
  content: "\f212"; }

.bi-calendar3-week::before {
  content: "\f213"; }

.bi-calendar3::before {
  content: "\f214"; }

.bi-calendar4-event::before {
  content: "\f215"; }

.bi-calendar4-range::before {
  content: "\f216"; }

.bi-calendar4-week::before {
  content: "\f217"; }

.bi-calendar4::before {
  content: "\f218"; }

.bi-camera-fill::before {
  content: "\f219"; }

.bi-camera-reels-fill::before {
  content: "\f21a"; }

.bi-camera-reels::before {
  content: "\f21b"; }

.bi-camera-video-fill::before {
  content: "\f21c"; }

.bi-camera-video-off-fill::before {
  content: "\f21d"; }

.bi-camera-video-off::before {
  content: "\f21e"; }

.bi-camera-video::before {
  content: "\f21f"; }

.bi-camera::before {
  content: "\f220"; }

.bi-camera2::before {
  content: "\f221"; }

.bi-capslock-fill::before {
  content: "\f222"; }

.bi-capslock::before {
  content: "\f223"; }

.bi-card-checklist::before {
  content: "\f224"; }

.bi-card-heading::before {
  content: "\f225"; }

.bi-card-image::before {
  content: "\f226"; }

.bi-card-list::before {
  content: "\f227"; }

.bi-card-text::before {
  content: "\f228"; }

.bi-caret-down-fill::before {
  content: "\f229"; }

.bi-caret-down-square-fill::before {
  content: "\f22a"; }

.bi-caret-down-square::before {
  content: "\f22b"; }

.bi-caret-down::before {
  content: "\f22c"; }

.bi-caret-left-fill::before {
  content: "\f22d"; }

.bi-caret-left-square-fill::before {
  content: "\f22e"; }

.bi-caret-left-square::before {
  content: "\f22f"; }

.bi-caret-left::before {
  content: "\f230"; }

.bi-caret-right-fill::before {
  content: "\f231"; }

.bi-caret-right-square-fill::before {
  content: "\f232"; }

.bi-caret-right-square::before {
  content: "\f233"; }

.bi-caret-right::before {
  content: "\f234"; }

.bi-caret-up-fill::before {
  content: "\f235"; }

.bi-caret-up-square-fill::before {
  content: "\f236"; }

.bi-caret-up-square::before {
  content: "\f237"; }

.bi-caret-up::before {
  content: "\f238"; }

.bi-cart-check-fill::before {
  content: "\f239"; }

.bi-cart-check::before {
  content: "\f23a"; }

.bi-cart-dash-fill::before {
  content: "\f23b"; }

.bi-cart-dash::before {
  content: "\f23c"; }

.bi-cart-fill::before {
  content: "\f23d"; }

.bi-cart-plus-fill::before {
  content: "\f23e"; }

.bi-cart-plus::before {
  content: "\f23f"; }

.bi-cart-x-fill::before {
  content: "\f240"; }

.bi-cart-x::before {
  content: "\f241"; }

.bi-cart::before {
  content: "\f242"; }

.bi-cart2::before {
  content: "\f243"; }

.bi-cart3::before {
  content: "\f244"; }

.bi-cart4::before {
  content: "\f245"; }

.bi-cash-stack::before {
  content: "\f246"; }

.bi-cash::before {
  content: "\f247"; }

.bi-cast::before {
  content: "\f248"; }

.bi-chat-dots-fill::before {
  content: "\f249"; }

.bi-chat-dots::before {
  content: "\f24a"; }

.bi-chat-fill::before {
  content: "\f24b"; }

.bi-chat-left-dots-fill::before {
  content: "\f24c"; }

.bi-chat-left-dots::before {
  content: "\f24d"; }

.bi-chat-left-fill::before {
  content: "\f24e"; }

.bi-chat-left-quote-fill::before {
  content: "\f24f"; }

.bi-chat-left-quote::before {
  content: "\f250"; }

.bi-chat-left-text-fill::before {
  content: "\f251"; }

.bi-chat-left-text::before {
  content: "\f252"; }

.bi-chat-left::before {
  content: "\f253"; }

.bi-chat-quote-fill::before {
  content: "\f254"; }

.bi-chat-quote::before {
  content: "\f255"; }

.bi-chat-right-dots-fill::before {
  content: "\f256"; }

.bi-chat-right-dots::before {
  content: "\f257"; }

.bi-chat-right-fill::before {
  content: "\f258"; }

.bi-chat-right-quote-fill::before {
  content: "\f259"; }

.bi-chat-right-quote::before {
  content: "\f25a"; }

.bi-chat-right-text-fill::before {
  content: "\f25b"; }

.bi-chat-right-text::before {
  content: "\f25c"; }

.bi-chat-right::before {
  content: "\f25d"; }

.bi-chat-square-dots-fill::before {
  content: "\f25e"; }

.bi-chat-square-dots::before {
  content: "\f25f"; }

.bi-chat-square-fill::before {
  content: "\f260"; }

.bi-chat-square-quote-fill::before {
  content: "\f261"; }

.bi-chat-square-quote::before {
  content: "\f262"; }

.bi-chat-square-text-fill::before {
  content: "\f263"; }

.bi-chat-square-text::before {
  content: "\f264"; }

.bi-chat-square::before {
  content: "\f265"; }

.bi-chat-text-fill::before {
  content: "\f266"; }

.bi-chat-text::before {
  content: "\f267"; }

.bi-chat::before {
  content: "\f268"; }

.bi-check-all::before {
  content: "\f269"; }

.bi-check-circle-fill::before {
  content: "\f26a"; }

.bi-check-circle::before {
  content: "\f26b"; }

.bi-check-square-fill::before {
  content: "\f26c"; }

.bi-check-square::before {
  content: "\f26d"; }

.bi-check::before {
  content: "\f26e"; }

.bi-check2-all::before {
  content: "\f26f"; }

.bi-check2-circle::before {
  content: "\f270"; }

.bi-check2-square::before {
  content: "\f271"; }

.bi-check2::before {
  content: "\f272"; }

.bi-chevron-bar-contract::before {
  content: "\f273"; }

.bi-chevron-bar-down::before {
  content: "\f274"; }

.bi-chevron-bar-expand::before {
  content: "\f275"; }

.bi-chevron-bar-left::before {
  content: "\f276"; }

.bi-chevron-bar-right::before {
  content: "\f277"; }

.bi-chevron-bar-up::before {
  content: "\f278"; }

.bi-chevron-compact-down::before {
  content: "\f279"; }

.bi-chevron-compact-left::before {
  content: "\f27a"; }

.bi-chevron-compact-right::before {
  content: "\f27b"; }

.bi-chevron-compact-up::before {
  content: "\f27c"; }

.bi-chevron-contract::before {
  content: "\f27d"; }

.bi-chevron-double-down::before {
  content: "\f27e"; }

.bi-chevron-double-left::before {
  content: "\f27f"; }

.bi-chevron-double-right::before {
  content: "\f280"; }

.bi-chevron-double-up::before {
  content: "\f281"; }

.bi-chevron-down::before {
  content: "\f282"; }

.bi-chevron-expand::before {
  content: "\f283"; }

.bi-chevron-left::before {
  content: "\f284"; }

.bi-chevron-right::before {
  content: "\f285"; }

.bi-chevron-up::before {
  content: "\f286"; }

.bi-circle-fill::before {
  content: "\f287"; }

.bi-circle-half::before {
  content: "\f288"; }

.bi-circle-square::before {
  content: "\f289"; }

.bi-circle::before {
  content: "\f28a"; }

.bi-clipboard-check::before {
  content: "\f28b"; }

.bi-clipboard-data::before {
  content: "\f28c"; }

.bi-clipboard-minus::before {
  content: "\f28d"; }

.bi-clipboard-plus::before {
  content: "\f28e"; }

.bi-clipboard-x::before {
  content: "\f28f"; }

.bi-clipboard::before {
  content: "\f290"; }

.bi-clock-fill::before {
  content: "\f291"; }

.bi-clock-history::before {
  content: "\f292"; }

.bi-clock::before {
  content: "\f293"; }

.bi-cloud-arrow-down-fill::before {
  content: "\f294"; }

.bi-cloud-arrow-down::before {
  content: "\f295"; }

.bi-cloud-arrow-up-fill::before {
  content: "\f296"; }

.bi-cloud-arrow-up::before {
  content: "\f297"; }

.bi-cloud-check-fill::before {
  content: "\f298"; }

.bi-cloud-check::before {
  content: "\f299"; }

.bi-cloud-download-fill::before {
  content: "\f29a"; }

.bi-cloud-download::before {
  content: "\f29b"; }

.bi-cloud-drizzle-fill::before {
  content: "\f29c"; }

.bi-cloud-drizzle::before {
  content: "\f29d"; }

.bi-cloud-fill::before {
  content: "\f29e"; }

.bi-cloud-fog-fill::before {
  content: "\f29f"; }

.bi-cloud-fog::before {
  content: "\f2a0"; }

.bi-cloud-fog2-fill::before {
  content: "\f2a1"; }

.bi-cloud-fog2::before {
  content: "\f2a2"; }

.bi-cloud-hail-fill::before {
  content: "\f2a3"; }

.bi-cloud-hail::before {
  content: "\f2a4"; }

.bi-cloud-haze-1::before {
  content: "\f2a5"; }

.bi-cloud-haze-fill::before {
  content: "\f2a6"; }

.bi-cloud-haze::before {
  content: "\f2a7"; }

.bi-cloud-haze2-fill::before {
  content: "\f2a8"; }

.bi-cloud-lightning-fill::before {
  content: "\f2a9"; }

.bi-cloud-lightning-rain-fill::before {
  content: "\f2aa"; }

.bi-cloud-lightning-rain::before {
  content: "\f2ab"; }

.bi-cloud-lightning::before {
  content: "\f2ac"; }

.bi-cloud-minus-fill::before {
  content: "\f2ad"; }

.bi-cloud-minus::before {
  content: "\f2ae"; }

.bi-cloud-moon-fill::before {
  content: "\f2af"; }

.bi-cloud-moon::before {
  content: "\f2b0"; }

.bi-cloud-plus-fill::before {
  content: "\f2b1"; }

.bi-cloud-plus::before {
  content: "\f2b2"; }

.bi-cloud-rain-fill::before {
  content: "\f2b3"; }

.bi-cloud-rain-heavy-fill::before {
  content: "\f2b4"; }

.bi-cloud-rain-heavy::before {
  content: "\f2b5"; }

.bi-cloud-rain::before {
  content: "\f2b6"; }

.bi-cloud-slash-fill::before {
  content: "\f2b7"; }

.bi-cloud-slash::before {
  content: "\f2b8"; }

.bi-cloud-sleet-fill::before {
  content: "\f2b9"; }

.bi-cloud-sleet::before {
  content: "\f2ba"; }

.bi-cloud-snow-fill::before {
  content: "\f2bb"; }

.bi-cloud-snow::before {
  content: "\f2bc"; }

.bi-cloud-sun-fill::before {
  content: "\f2bd"; }

.bi-cloud-sun::before {
  content: "\f2be"; }

.bi-cloud-upload-fill::before {
  content: "\f2bf"; }

.bi-cloud-upload::before {
  content: "\f2c0"; }

.bi-cloud::before {
  content: "\f2c1"; }

.bi-clouds-fill::before {
  content: "\f2c2"; }

.bi-clouds::before {
  content: "\f2c3"; }

.bi-cloudy-fill::before {
  content: "\f2c4"; }

.bi-cloudy::before {
  content: "\f2c5"; }

.bi-code-slash::before {
  content: "\f2c6"; }

.bi-code-square::before {
  content: "\f2c7"; }

.bi-code::before {
  content: "\f2c8"; }

.bi-collection-fill::before {
  content: "\f2c9"; }

.bi-collection-play-fill::before {
  content: "\f2ca"; }

.bi-collection-play::before {
  content: "\f2cb"; }

.bi-collection::before {
  content: "\f2cc"; }

.bi-columns-gap::before {
  content: "\f2cd"; }

.bi-columns::before {
  content: "\f2ce"; }

.bi-command::before {
  content: "\f2cf"; }

.bi-compass-fill::before {
  content: "\f2d0"; }

.bi-compass::before {
  content: "\f2d1"; }

.bi-cone-striped::before {
  content: "\f2d2"; }

.bi-cone::before {
  content: "\f2d3"; }

.bi-controller::before {
  content: "\f2d4"; }

.bi-cpu-fill::before {
  content: "\f2d5"; }

.bi-cpu::before {
  content: "\f2d6"; }

.bi-credit-card-2-back-fill::before {
  content: "\f2d7"; }

.bi-credit-card-2-back::before {
  content: "\f2d8"; }

.bi-credit-card-2-front-fill::before {
  content: "\f2d9"; }

.bi-credit-card-2-front::before {
  content: "\f2da"; }

.bi-credit-card-fill::before {
  content: "\f2db"; }

.bi-credit-card::before {
  content: "\f2dc"; }

.bi-crop::before {
  content: "\f2dd"; }

.bi-cup-fill::before {
  content: "\f2de"; }

.bi-cup-straw::before {
  content: "\f2df"; }

.bi-cup::before {
  content: "\f2e0"; }

.bi-cursor-fill::before {
  content: "\f2e1"; }

.bi-cursor-text::before {
  content: "\f2e2"; }

.bi-cursor::before {
  content: "\f2e3"; }

.bi-dash-circle-dotted::before {
  content: "\f2e4"; }

.bi-dash-circle-fill::before {
  content: "\f2e5"; }

.bi-dash-circle::before {
  content: "\f2e6"; }

.bi-dash-square-dotted::before {
  content: "\f2e7"; }

.bi-dash-square-fill::before {
  content: "\f2e8"; }

.bi-dash-square::before {
  content: "\f2e9"; }

.bi-dash::before {
  content: "\f2ea"; }

.bi-diagram-2-fill::before {
  content: "\f2eb"; }

.bi-diagram-2::before {
  content: "\f2ec"; }

.bi-diagram-3-fill::before {
  content: "\f2ed"; }

.bi-diagram-3::before {
  content: "\f2ee"; }

.bi-diamond-fill::before {
  content: "\f2ef"; }

.bi-diamond-half::before {
  content: "\f2f0"; }

.bi-diamond::before {
  content: "\f2f1"; }

.bi-dice-1-fill::before {
  content: "\f2f2"; }

.bi-dice-1::before {
  content: "\f2f3"; }

.bi-dice-2-fill::before {
  content: "\f2f4"; }

.bi-dice-2::before {
  content: "\f2f5"; }

.bi-dice-3-fill::before {
  content: "\f2f6"; }

.bi-dice-3::before {
  content: "\f2f7"; }

.bi-dice-4-fill::before {
  content: "\f2f8"; }

.bi-dice-4::before {
  content: "\f2f9"; }

.bi-dice-5-fill::before {
  content: "\f2fa"; }

.bi-dice-5::before {
  content: "\f2fb"; }

.bi-dice-6-fill::before {
  content: "\f2fc"; }

.bi-dice-6::before {
  content: "\f2fd"; }

.bi-disc-fill::before {
  content: "\f2fe"; }

.bi-disc::before {
  content: "\f2ff"; }

.bi-discord::before {
  content: "\f300"; }

.bi-display-fill::before {
  content: "\f301"; }

.bi-display::before {
  content: "\f302"; }

.bi-distribute-horizontal::before {
  content: "\f303"; }

.bi-distribute-vertical::before {
  content: "\f304"; }

.bi-door-closed-fill::before {
  content: "\f305"; }

.bi-door-closed::before {
  content: "\f306"; }

.bi-door-open-fill::before {
  content: "\f307"; }

.bi-door-open::before {
  content: "\f308"; }

.bi-dot::before {
  content: "\f309"; }

.bi-download::before {
  content: "\f30a"; }

.bi-droplet-fill::before {
  content: "\f30b"; }

.bi-droplet-half::before {
  content: "\f30c"; }

.bi-droplet::before {
  content: "\f30d"; }

.bi-earbuds::before {
  content: "\f30e"; }

.bi-easel-fill::before {
  content: "\f30f"; }

.bi-easel::before {
  content: "\f310"; }

.bi-egg-fill::before {
  content: "\f311"; }

.bi-egg-fried::before {
  content: "\f312"; }

.bi-egg::before {
  content: "\f313"; }

.bi-eject-fill::before {
  content: "\f314"; }

.bi-eject::before {
  content: "\f315"; }

.bi-emoji-angry-fill::before {
  content: "\f316"; }

.bi-emoji-angry::before {
  content: "\f317"; }

.bi-emoji-dizzy-fill::before {
  content: "\f318"; }

.bi-emoji-dizzy::before {
  content: "\f319"; }

.bi-emoji-expressionless-fill::before {
  content: "\f31a"; }

.bi-emoji-expressionless::before {
  content: "\f31b"; }

.bi-emoji-frown-fill::before {
  content: "\f31c"; }

.bi-emoji-frown::before {
  content: "\f31d"; }

.bi-emoji-heart-eyes-fill::before {
  content: "\f31e"; }

.bi-emoji-heart-eyes::before {
  content: "\f31f"; }

.bi-emoji-laughing-fill::before {
  content: "\f320"; }

.bi-emoji-laughing::before {
  content: "\f321"; }

.bi-emoji-neutral-fill::before {
  content: "\f322"; }

.bi-emoji-neutral::before {
  content: "\f323"; }

.bi-emoji-smile-fill::before {
  content: "\f324"; }

.bi-emoji-smile-upside-down-fill::before {
  content: "\f325"; }

.bi-emoji-smile-upside-down::before {
  content: "\f326"; }

.bi-emoji-smile::before {
  content: "\f327"; }

.bi-emoji-sunglasses-fill::before {
  content: "\f328"; }

.bi-emoji-sunglasses::before {
  content: "\f329"; }

.bi-emoji-wink-fill::before {
  content: "\f32a"; }

.bi-emoji-wink::before {
  content: "\f32b"; }

.bi-envelope-fill::before {
  content: "\f32c"; }

.bi-envelope-open-fill::before {
  content: "\f32d"; }

.bi-envelope-open::before {
  content: "\f32e"; }

.bi-envelope::before {
  content: "\f32f"; }

.bi-eraser-fill::before {
  content: "\f330"; }

.bi-eraser::before {
  content: "\f331"; }

.bi-exclamation-circle-fill::before {
  content: "\f332"; }

.bi-exclamation-circle::before {
  content: "\f333"; }

.bi-exclamation-diamond-fill::before {
  content: "\f334"; }

.bi-exclamation-diamond::before {
  content: "\f335"; }

.bi-exclamation-octagon-fill::before {
  content: "\f336"; }

.bi-exclamation-octagon::before {
  content: "\f337"; }

.bi-exclamation-square-fill::before {
  content: "\f338"; }

.bi-exclamation-square::before {
  content: "\f339"; }

.bi-exclamation-triangle-fill::before {
  content: "\f33a"; }

.bi-exclamation-triangle::before {
  content: "\f33b"; }

.bi-exclamation::before {
  content: "\f33c"; }

.bi-exclude::before {
  content: "\f33d"; }

.bi-eye-fill::before {
  content: "\f33e"; }

.bi-eye-slash-fill::before {
  content: "\f33f"; }

.bi-eye-slash::before {
  content: "\f340"; }

.bi-eye::before {
  content: "\f341"; }

.bi-eyedropper::before {
  content: "\f342"; }

.bi-eyeglasses::before {
  content: "\f343"; }

.bi-facebook::before {
  content: "\f344"; }

.bi-file-arrow-down-fill::before {
  content: "\f345"; }

.bi-file-arrow-down::before {
  content: "\f346"; }

.bi-file-arrow-up-fill::before {
  content: "\f347"; }

.bi-file-arrow-up::before {
  content: "\f348"; }

.bi-file-bar-graph-fill::before {
  content: "\f349"; }

.bi-file-bar-graph::before {
  content: "\f34a"; }

.bi-file-binary-fill::before {
  content: "\f34b"; }

.bi-file-binary::before {
  content: "\f34c"; }

.bi-file-break-fill::before {
  content: "\f34d"; }

.bi-file-break::before {
  content: "\f34e"; }

.bi-file-check-fill::before {
  content: "\f34f"; }

.bi-file-check::before {
  content: "\f350"; }

.bi-file-code-fill::before {
  content: "\f351"; }

.bi-file-code::before {
  content: "\f352"; }

.bi-file-diff-fill::before {
  content: "\f353"; }

.bi-file-diff::before {
  content: "\f354"; }

.bi-file-earmark-arrow-down-fill::before {
  content: "\f355"; }

.bi-file-earmark-arrow-down::before {
  content: "\f356"; }

.bi-file-earmark-arrow-up-fill::before {
  content: "\f357"; }

.bi-file-earmark-arrow-up::before {
  content: "\f358"; }

.bi-file-earmark-bar-graph-fill::before {
  content: "\f359"; }

.bi-file-earmark-bar-graph::before {
  content: "\f35a"; }

.bi-file-earmark-binary-fill::before {
  content: "\f35b"; }

.bi-file-earmark-binary::before {
  content: "\f35c"; }

.bi-file-earmark-break-fill::before {
  content: "\f35d"; }

.bi-file-earmark-break::before {
  content: "\f35e"; }

.bi-file-earmark-check-fill::before {
  content: "\f35f"; }

.bi-file-earmark-check::before {
  content: "\f360"; }

.bi-file-earmark-code-fill::before {
  content: "\f361"; }

.bi-file-earmark-code::before {
  content: "\f362"; }

.bi-file-earmark-diff-fill::before {
  content: "\f363"; }

.bi-file-earmark-diff::before {
  content: "\f364"; }

.bi-file-earmark-easel-fill::before {
  content: "\f365"; }

.bi-file-earmark-easel::before {
  content: "\f366"; }

.bi-file-earmark-excel-fill::before {
  content: "\f367"; }

.bi-file-earmark-excel::before {
  content: "\f368"; }

.bi-file-earmark-fill::before {
  content: "\f369"; }

.bi-file-earmark-font-fill::before {
  content: "\f36a"; }

.bi-file-earmark-font::before {
  content: "\f36b"; }

.bi-file-earmark-image-fill::before {
  content: "\f36c"; }

.bi-file-earmark-image::before {
  content: "\f36d"; }

.bi-file-earmark-lock-fill::before {
  content: "\f36e"; }

.bi-file-earmark-lock::before {
  content: "\f36f"; }

.bi-file-earmark-lock2-fill::before {
  content: "\f370"; }

.bi-file-earmark-lock2::before {
  content: "\f371"; }

.bi-file-earmark-medical-fill::before {
  content: "\f372"; }

.bi-file-earmark-medical::before {
  content: "\f373"; }

.bi-file-earmark-minus-fill::before {
  content: "\f374"; }

.bi-file-earmark-minus::before {
  content: "\f375"; }

.bi-file-earmark-music-fill::before {
  content: "\f376"; }

.bi-file-earmark-music::before {
  content: "\f377"; }

.bi-file-earmark-person-fill::before {
  content: "\f378"; }

.bi-file-earmark-person::before {
  content: "\f379"; }

.bi-file-earmark-play-fill::before {
  content: "\f37a"; }

.bi-file-earmark-play::before {
  content: "\f37b"; }

.bi-file-earmark-plus-fill::before {
  content: "\f37c"; }

.bi-file-earmark-plus::before {
  content: "\f37d"; }

.bi-file-earmark-post-fill::before {
  content: "\f37e"; }

.bi-file-earmark-post::before {
  content: "\f37f"; }

.bi-file-earmark-ppt-fill::before {
  content: "\f380"; }

.bi-file-earmark-ppt::before {
  content: "\f381"; }

.bi-file-earmark-richtext-fill::before {
  content: "\f382"; }

.bi-file-earmark-richtext::before {
  content: "\f383"; }

.bi-file-earmark-ruled-fill::before {
  content: "\f384"; }

.bi-file-earmark-ruled::before {
  content: "\f385"; }

.bi-file-earmark-slides-fill::before {
  content: "\f386"; }

.bi-file-earmark-slides::before {
  content: "\f387"; }

.bi-file-earmark-spreadsheet-fill::before {
  content: "\f388"; }

.bi-file-earmark-spreadsheet::before {
  content: "\f389"; }

.bi-file-earmark-text-fill::before {
  content: "\f38a"; }

.bi-file-earmark-text::before {
  content: "\f38b"; }

.bi-file-earmark-word-fill::before {
  content: "\f38c"; }

.bi-file-earmark-word::before {
  content: "\f38d"; }

.bi-file-earmark-x-fill::before {
  content: "\f38e"; }

.bi-file-earmark-x::before {
  content: "\f38f"; }

.bi-file-earmark-zip-fill::before {
  content: "\f390"; }

.bi-file-earmark-zip::before {
  content: "\f391"; }

.bi-file-earmark::before {
  content: "\f392"; }

.bi-file-easel-fill::before {
  content: "\f393"; }

.bi-file-easel::before {
  content: "\f394"; }

.bi-file-excel-fill::before {
  content: "\f395"; }

.bi-file-excel::before {
  content: "\f396"; }

.bi-file-fill::before {
  content: "\f397"; }

.bi-file-font-fill::before {
  content: "\f398"; }

.bi-file-font::before {
  content: "\f399"; }

.bi-file-image-fill::before {
  content: "\f39a"; }

.bi-file-image::before {
  content: "\f39b"; }

.bi-file-lock-fill::before {
  content: "\f39c"; }

.bi-file-lock::before {
  content: "\f39d"; }

.bi-file-lock2-fill::before {
  content: "\f39e"; }

.bi-file-lock2::before {
  content: "\f39f"; }

.bi-file-medical-fill::before {
  content: "\f3a0"; }

.bi-file-medical::before {
  content: "\f3a1"; }

.bi-file-minus-fill::before {
  content: "\f3a2"; }

.bi-file-minus::before {
  content: "\f3a3"; }

.bi-file-music-fill::before {
  content: "\f3a4"; }

.bi-file-music::before {
  content: "\f3a5"; }

.bi-file-person-fill::before {
  content: "\f3a6"; }

.bi-file-person::before {
  content: "\f3a7"; }

.bi-file-play-fill::before {
  content: "\f3a8"; }

.bi-file-play::before {
  content: "\f3a9"; }

.bi-file-plus-fill::before {
  content: "\f3aa"; }

.bi-file-plus::before {
  content: "\f3ab"; }

.bi-file-post-fill::before {
  content: "\f3ac"; }

.bi-file-post::before {
  content: "\f3ad"; }

.bi-file-ppt-fill::before {
  content: "\f3ae"; }

.bi-file-ppt::before {
  content: "\f3af"; }

.bi-file-richtext-fill::before {
  content: "\f3b0"; }

.bi-file-richtext::before {
  content: "\f3b1"; }

.bi-file-ruled-fill::before {
  content: "\f3b2"; }

.bi-file-ruled::before {
  content: "\f3b3"; }

.bi-file-slides-fill::before {
  content: "\f3b4"; }

.bi-file-slides::before {
  content: "\f3b5"; }

.bi-file-spreadsheet-fill::before {
  content: "\f3b6"; }

.bi-file-spreadsheet::before {
  content: "\f3b7"; }

.bi-file-text-fill::before {
  content: "\f3b8"; }

.bi-file-text::before {
  content: "\f3b9"; }

.bi-file-word-fill::before {
  content: "\f3ba"; }

.bi-file-word::before {
  content: "\f3bb"; }

.bi-file-x-fill::before {
  content: "\f3bc"; }

.bi-file-x::before {
  content: "\f3bd"; }

.bi-file-zip-fill::before {
  content: "\f3be"; }

.bi-file-zip::before {
  content: "\f3bf"; }

.bi-file::before {
  content: "\f3c0"; }

.bi-files-alt::before {
  content: "\f3c1"; }

.bi-files::before {
  content: "\f3c2"; }

.bi-film::before {
  content: "\f3c3"; }

.bi-filter-circle-fill::before {
  content: "\f3c4"; }

.bi-filter-circle::before {
  content: "\f3c5"; }

.bi-filter-left::before {
  content: "\f3c6"; }

.bi-filter-right::before {
  content: "\f3c7"; }

.bi-filter-square-fill::before {
  content: "\f3c8"; }

.bi-filter-square::before {
  content: "\f3c9"; }

.bi-filter::before {
  content: "\f3ca"; }

.bi-flag-fill::before {
  content: "\f3cb"; }

.bi-flag::before {
  content: "\f3cc"; }

.bi-flower1::before {
  content: "\f3cd"; }

.bi-flower2::before {
  content: "\f3ce"; }

.bi-flower3::before {
  content: "\f3cf"; }

.bi-folder-check::before {
  content: "\f3d0"; }

.bi-folder-fill::before {
  content: "\f3d1"; }

.bi-folder-minus::before {
  content: "\f3d2"; }

.bi-folder-plus::before {
  content: "\f3d3"; }

.bi-folder-symlink-fill::before {
  content: "\f3d4"; }

.bi-folder-symlink::before {
  content: "\f3d5"; }

.bi-folder-x::before {
  content: "\f3d6"; }

.bi-folder::before {
  content: "\f3d7"; }

.bi-folder2-open::before {
  content: "\f3d8"; }

.bi-folder2::before {
  content: "\f3d9"; }

.bi-fonts::before {
  content: "\f3da"; }

.bi-forward-fill::before {
  content: "\f3db"; }

.bi-forward::before {
  content: "\f3dc"; }

.bi-front::before {
  content: "\f3dd"; }

.bi-fullscreen-exit::before {
  content: "\f3de"; }

.bi-fullscreen::before {
  content: "\f3df"; }

.bi-funnel-fill::before {
  content: "\f3e0"; }

.bi-funnel::before {
  content: "\f3e1"; }

.bi-gear-fill::before {
  content: "\f3e2"; }

.bi-gear-wide-connected::before {
  content: "\f3e3"; }

.bi-gear-wide::before {
  content: "\f3e4"; }

.bi-gear::before {
  content: "\f3e5"; }

.bi-gem::before {
  content: "\f3e6"; }

.bi-geo-alt-fill::before {
  content: "\f3e7"; }

.bi-geo-alt::before {
  content: "\f3e8"; }

.bi-geo-fill::before {
  content: "\f3e9"; }

.bi-geo::before {
  content: "\f3ea"; }

.bi-gift-fill::before {
  content: "\f3eb"; }

.bi-gift::before {
  content: "\f3ec"; }

.bi-github::before {
  content: "\f3ed"; }

.bi-globe::before {
  content: "\f3ee"; }

.bi-globe2::before {
  content: "\f3ef"; }

.bi-google::before {
  content: "\f3f0"; }

.bi-graph-down::before {
  content: "\f3f1"; }

.bi-graph-up::before {
  content: "\f3f2"; }

.bi-grid-1x2-fill::before {
  content: "\f3f3"; }

.bi-grid-1x2::before {
  content: "\f3f4"; }

.bi-grid-3x2-gap-fill::before {
  content: "\f3f5"; }

.bi-grid-3x2-gap::before {
  content: "\f3f6"; }

.bi-grid-3x2::before {
  content: "\f3f7"; }

.bi-grid-3x3-gap-fill::before {
  content: "\f3f8"; }

.bi-grid-3x3-gap::before {
  content: "\f3f9"; }

.bi-grid-3x3::before {
  content: "\f3fa"; }

.bi-grid-fill::before {
  content: "\f3fb"; }

.bi-grid::before {
  content: "\f3fc"; }

.bi-grip-horizontal::before {
  content: "\f3fd"; }

.bi-grip-vertical::before {
  content: "\f3fe"; }

.bi-hammer::before {
  content: "\f3ff"; }

.bi-hand-index-fill::before {
  content: "\f400"; }

.bi-hand-index-thumb-fill::before {
  content: "\f401"; }

.bi-hand-index-thumb::before {
  content: "\f402"; }

.bi-hand-index::before {
  content: "\f403"; }

.bi-hand-thumbs-down-fill::before {
  content: "\f404"; }

.bi-hand-thumbs-down::before {
  content: "\f405"; }

.bi-hand-thumbs-up-fill::before {
  content: "\f406"; }

.bi-hand-thumbs-up::before {
  content: "\f407"; }

.bi-handbag-fill::before {
  content: "\f408"; }

.bi-handbag::before {
  content: "\f409"; }

.bi-hash::before {
  content: "\f40a"; }

.bi-hdd-fill::before {
  content: "\f40b"; }

.bi-hdd-network-fill::before {
  content: "\f40c"; }

.bi-hdd-network::before {
  content: "\f40d"; }

.bi-hdd-rack-fill::before {
  content: "\f40e"; }

.bi-hdd-rack::before {
  content: "\f40f"; }

.bi-hdd-stack-fill::before {
  content: "\f410"; }

.bi-hdd-stack::before {
  content: "\f411"; }

.bi-hdd::before {
  content: "\f412"; }

.bi-headphones::before {
  content: "\f413"; }

.bi-headset::before {
  content: "\f414"; }

.bi-heart-fill::before {
  content: "\f415"; }

.bi-heart-half::before {
  content: "\f416"; }

.bi-heart::before {
  content: "\f417"; }

.bi-heptagon-fill::before {
  content: "\f418"; }

.bi-heptagon-half::before {
  content: "\f419"; }

.bi-heptagon::before {
  content: "\f41a"; }

.bi-hexagon-fill::before {
  content: "\f41b"; }

.bi-hexagon-half::before {
  content: "\f41c"; }

.bi-hexagon::before {
  content: "\f41d"; }

.bi-hourglass-bottom::before {
  content: "\f41e"; }

.bi-hourglass-split::before {
  content: "\f41f"; }

.bi-hourglass-top::before {
  content: "\f420"; }

.bi-hourglass::before {
  content: "\f421"; }

.bi-house-door-fill::before {
  content: "\f422"; }

.bi-house-door::before {
  content: "\f423"; }

.bi-house-fill::before {
  content: "\f424"; }

.bi-house::before {
  content: "\f425"; }

.bi-hr::before {
  content: "\f426"; }

.bi-hurricane::before {
  content: "\f427"; }

.bi-image-alt::before {
  content: "\f428"; }

.bi-image-fill::before {
  content: "\f429"; }

.bi-image::before {
  content: "\f42a"; }

.bi-images::before {
  content: "\f42b"; }

.bi-inbox-fill::before {
  content: "\f42c"; }

.bi-inbox::before {
  content: "\f42d"; }

.bi-inboxes-fill::before {
  content: "\f42e"; }

.bi-inboxes::before {
  content: "\f42f"; }

.bi-info-circle-fill::before {
  content: "\f430"; }

.bi-info-circle::before {
  content: "\f431"; }

.bi-info-square-fill::before {
  content: "\f432"; }

.bi-info-square::before {
  content: "\f433"; }

.bi-info::before {
  content: "\f434"; }

.bi-input-cursor-text::before {
  content: "\f435"; }

.bi-input-cursor::before {
  content: "\f436"; }

.bi-instagram::before {
  content: "\f437"; }

.bi-intersect::before {
  content: "\f438"; }

.bi-journal-album::before {
  content: "\f439"; }

.bi-journal-arrow-down::before {
  content: "\f43a"; }

.bi-journal-arrow-up::before {
  content: "\f43b"; }

.bi-journal-bookmark-fill::before {
  content: "\f43c"; }

.bi-journal-bookmark::before {
  content: "\f43d"; }

.bi-journal-check::before {
  content: "\f43e"; }

.bi-journal-code::before {
  content: "\f43f"; }

.bi-journal-medical::before {
  content: "\f440"; }

.bi-journal-minus::before {
  content: "\f441"; }

.bi-journal-plus::before {
  content: "\f442"; }

.bi-journal-richtext::before {
  content: "\f443"; }

.bi-journal-text::before {
  content: "\f444"; }

.bi-journal-x::before {
  content: "\f445"; }

.bi-journal::before {
  content: "\f446"; }

.bi-journals::before {
  content: "\f447"; }

.bi-joystick::before {
  content: "\f448"; }

.bi-justify-left::before {
  content: "\f449"; }

.bi-justify-right::before {
  content: "\f44a"; }

.bi-justify::before {
  content: "\f44b"; }

.bi-kanban-fill::before {
  content: "\f44c"; }

.bi-kanban::before {
  content: "\f44d"; }

.bi-key-fill::before {
  content: "\f44e"; }

.bi-key::before {
  content: "\f44f"; }

.bi-keyboard-fill::before {
  content: "\f450"; }

.bi-keyboard::before {
  content: "\f451"; }

.bi-ladder::before {
  content: "\f452"; }

.bi-lamp-fill::before {
  content: "\f453"; }

.bi-lamp::before {
  content: "\f454"; }

.bi-laptop-fill::before {
  content: "\f455"; }

.bi-laptop::before {
  content: "\f456"; }

.bi-layer-backward::before {
  content: "\f457"; }

.bi-layer-forward::before {
  content: "\f458"; }

.bi-layers-fill::before {
  content: "\f459"; }

.bi-layers-half::before {
  content: "\f45a"; }

.bi-layers::before {
  content: "\f45b"; }

.bi-layout-sidebar-inset-reverse::before {
  content: "\f45c"; }

.bi-layout-sidebar-inset::before {
  content: "\f45d"; }

.bi-layout-sidebar-reverse::before {
  content: "\f45e"; }

.bi-layout-sidebar::before {
  content: "\f45f"; }

.bi-layout-split::before {
  content: "\f460"; }

.bi-layout-text-sidebar-reverse::before {
  content: "\f461"; }

.bi-layout-text-sidebar::before {
  content: "\f462"; }

.bi-layout-text-window-reverse::before {
  content: "\f463"; }

.bi-layout-text-window::before {
  content: "\f464"; }

.bi-layout-three-columns::before {
  content: "\f465"; }

.bi-layout-wtf::before {
  content: "\f466"; }

.bi-life-preserver::before {
  content: "\f467"; }

.bi-lightbulb-fill::before {
  content: "\f468"; }

.bi-lightbulb-off-fill::before {
  content: "\f469"; }

.bi-lightbulb-off::before {
  content: "\f46a"; }

.bi-lightbulb::before {
  content: "\f46b"; }

.bi-lightning-charge-fill::before {
  content: "\f46c"; }

.bi-lightning-charge::before {
  content: "\f46d"; }

.bi-lightning-fill::before {
  content: "\f46e"; }

.bi-lightning::before {
  content: "\f46f"; }

.bi-link-45deg::before {
  content: "\f470"; }

.bi-link::before {
  content: "\f471"; }

.bi-linkedin::before {
  content: "\f472"; }

.bi-list-check::before {
  content: "\f473"; }

.bi-list-nested::before {
  content: "\f474"; }

.bi-list-ol::before {
  content: "\f475"; }

.bi-list-stars::before {
  content: "\f476"; }

.bi-list-task::before {
  content: "\f477"; }

.bi-list-ul::before {
  content: "\f478"; }

.bi-list::before {
  content: "\f479"; }

.bi-lock-fill::before {
  content: "\f47a"; }

.bi-lock::before {
  content: "\f47b"; }

.bi-mailbox::before {
  content: "\f47c"; }

.bi-mailbox2::before {
  content: "\f47d"; }

.bi-map-fill::before {
  content: "\f47e"; }

.bi-map::before {
  content: "\f47f"; }

.bi-markdown-fill::before {
  content: "\f480"; }

.bi-markdown::before {
  content: "\f481"; }

.bi-mask::before {
  content: "\f482"; }

.bi-megaphone-fill::before {
  content: "\f483"; }

.bi-megaphone::before {
  content: "\f484"; }

.bi-menu-app-fill::before {
  content: "\f485"; }

.bi-menu-app::before {
  content: "\f486"; }

.bi-menu-button-fill::before {
  content: "\f487"; }

.bi-menu-button-wide-fill::before {
  content: "\f488"; }

.bi-menu-button-wide::before {
  content: "\f489"; }

.bi-menu-button::before {
  content: "\f48a"; }

.bi-menu-down::before {
  content: "\f48b"; }

.bi-menu-up::before {
  content: "\f48c"; }

.bi-mic-fill::before {
  content: "\f48d"; }

.bi-mic-mute-fill::before {
  content: "\f48e"; }

.bi-mic-mute::before {
  content: "\f48f"; }

.bi-mic::before {
  content: "\f490"; }

.bi-minecart-loaded::before {
  content: "\f491"; }

.bi-minecart::before {
  content: "\f492"; }

.bi-moisture::before {
  content: "\f493"; }

.bi-moon-fill::before {
  content: "\f494"; }

.bi-moon-stars-fill::before {
  content: "\f495"; }

.bi-moon-stars::before {
  content: "\f496"; }

.bi-moon::before {
  content: "\f497"; }

.bi-mouse-fill::before {
  content: "\f498"; }

.bi-mouse::before {
  content: "\f499"; }

.bi-mouse2-fill::before {
  content: "\f49a"; }

.bi-mouse2::before {
  content: "\f49b"; }

.bi-mouse3-fill::before {
  content: "\f49c"; }

.bi-mouse3::before {
  content: "\f49d"; }

.bi-music-note-beamed::before {
  content: "\f49e"; }

.bi-music-note-list::before {
  content: "\f49f"; }

.bi-music-note::before {
  content: "\f4a0"; }

.bi-music-player-fill::before {
  content: "\f4a1"; }

.bi-music-player::before {
  content: "\f4a2"; }

.bi-newspaper::before {
  content: "\f4a3"; }

.bi-node-minus-fill::before {
  content: "\f4a4"; }

.bi-node-minus::before {
  content: "\f4a5"; }

.bi-node-plus-fill::before {
  content: "\f4a6"; }

.bi-node-plus::before {
  content: "\f4a7"; }

.bi-nut-fill::before {
  content: "\f4a8"; }

.bi-nut::before {
  content: "\f4a9"; }

.bi-octagon-fill::before {
  content: "\f4aa"; }

.bi-octagon-half::before {
  content: "\f4ab"; }

.bi-octagon::before {
  content: "\f4ac"; }

.bi-option::before {
  content: "\f4ad"; }

.bi-outlet::before {
  content: "\f4ae"; }

.bi-paint-bucket::before {
  content: "\f4af"; }

.bi-palette-fill::before {
  content: "\f4b0"; }

.bi-palette::before {
  content: "\f4b1"; }

.bi-palette2::before {
  content: "\f4b2"; }

.bi-paperclip::before {
  content: "\f4b3"; }

.bi-paragraph::before {
  content: "\f4b4"; }

.bi-patch-check-fill::before {
  content: "\f4b5"; }

.bi-patch-check::before {
  content: "\f4b6"; }

.bi-patch-exclamation-fill::before {
  content: "\f4b7"; }

.bi-patch-exclamation::before {
  content: "\f4b8"; }

.bi-patch-minus-fill::before {
  content: "\f4b9"; }

.bi-patch-minus::before {
  content: "\f4ba"; }

.bi-patch-plus-fill::before {
  content: "\f4bb"; }

.bi-patch-plus::before {
  content: "\f4bc"; }

.bi-patch-question-fill::before {
  content: "\f4bd"; }

.bi-patch-question::before {
  content: "\f4be"; }

.bi-pause-btn-fill::before {
  content: "\f4bf"; }

.bi-pause-btn::before {
  content: "\f4c0"; }

.bi-pause-circle-fill::before {
  content: "\f4c1"; }

.bi-pause-circle::before {
  content: "\f4c2"; }

.bi-pause-fill::before {
  content: "\f4c3"; }

.bi-pause::before {
  content: "\f4c4"; }

.bi-peace-fill::before {
  content: "\f4c5"; }

.bi-peace::before {
  content: "\f4c6"; }

.bi-pen-fill::before {
  content: "\f4c7"; }

.bi-pen::before {
  content: "\f4c8"; }

.bi-pencil-fill::before {
  content: "\f4c9"; }

.bi-pencil-square::before {
  content: "\f4ca"; }

.bi-pencil::before {
  content: "\f4cb"; }

.bi-pentagon-fill::before {
  content: "\f4cc"; }

.bi-pentagon-half::before {
  content: "\f4cd"; }

.bi-pentagon::before {
  content: "\f4ce"; }

.bi-people-fill::before {
  content: "\f4cf"; }

.bi-people::before {
  content: "\f4d0"; }

.bi-percent::before {
  content: "\f4d1"; }

.bi-person-badge-fill::before {
  content: "\f4d2"; }

.bi-person-badge::before {
  content: "\f4d3"; }

.bi-person-bounding-box::before {
  content: "\f4d4"; }

.bi-person-check-fill::before {
  content: "\f4d5"; }

.bi-person-check::before {
  content: "\f4d6"; }

.bi-person-circle::before {
  content: "\f4d7"; }

.bi-person-dash-fill::before {
  content: "\f4d8"; }

.bi-person-dash::before {
  content: "\f4d9"; }

.bi-person-fill::before {
  content: "\f4da"; }

.bi-person-lines-fill::before {
  content: "\f4db"; }

.bi-person-plus-fill::before {
  content: "\f4dc"; }

.bi-person-plus::before {
  content: "\f4dd"; }

.bi-person-square::before {
  content: "\f4de"; }

.bi-person-x-fill::before {
  content: "\f4df"; }

.bi-person-x::before {
  content: "\f4e0"; }

.bi-person::before {
  content: "\f4e1"; }

.bi-phone-fill::before {
  content: "\f4e2"; }

.bi-phone-landscape-fill::before {
  content: "\f4e3"; }

.bi-phone-landscape::before {
  content: "\f4e4"; }

.bi-phone-vibrate-fill::before {
  content: "\f4e5"; }

.bi-phone-vibrate::before {
  content: "\f4e6"; }

.bi-phone::before {
  content: "\f4e7"; }

.bi-pie-chart-fill::before {
  content: "\f4e8"; }

.bi-pie-chart::before {
  content: "\f4e9"; }

.bi-pin-angle-fill::before {
  content: "\f4ea"; }

.bi-pin-angle::before {
  content: "\f4eb"; }

.bi-pin-fill::before {
  content: "\f4ec"; }

.bi-pin::before {
  content: "\f4ed"; }

.bi-pip-fill::before {
  content: "\f4ee"; }

.bi-pip::before {
  content: "\f4ef"; }

.bi-play-btn-fill::before {
  content: "\f4f0"; }

.bi-play-btn::before {
  content: "\f4f1"; }

.bi-play-circle-fill::before {
  content: "\f4f2"; }

.bi-play-circle::before {
  content: "\f4f3"; }

.bi-play-fill::before {
  content: "\f4f4"; }

.bi-play::before {
  content: "\f4f5"; }

.bi-plug-fill::before {
  content: "\f4f6"; }

.bi-plug::before {
  content: "\f4f7"; }

.bi-plus-circle-dotted::before {
  content: "\f4f8"; }

.bi-plus-circle-fill::before {
  content: "\f4f9"; }

.bi-plus-circle::before {
  content: "\f4fa"; }

.bi-plus-square-dotted::before {
  content: "\f4fb"; }

.bi-plus-square-fill::before {
  content: "\f4fc"; }

.bi-plus-square::before {
  content: "\f4fd"; }

.bi-plus::before {
  content: "\f4fe"; }

.bi-power::before {
  content: "\f4ff"; }

.bi-printer-fill::before {
  content: "\f500"; }

.bi-printer::before {
  content: "\f501"; }

.bi-puzzle-fill::before {
  content: "\f502"; }

.bi-puzzle::before {
  content: "\f503"; }

.bi-question-circle-fill::before {
  content: "\f504"; }

.bi-question-circle::before {
  content: "\f505"; }

.bi-question-diamond-fill::before {
  content: "\f506"; }

.bi-question-diamond::before {
  content: "\f507"; }

.bi-question-octagon-fill::before {
  content: "\f508"; }

.bi-question-octagon::before {
  content: "\f509"; }

.bi-question-square-fill::before {
  content: "\f50a"; }

.bi-question-square::before {
  content: "\f50b"; }

.bi-question::before {
  content: "\f50c"; }

.bi-rainbow::before {
  content: "\f50d"; }

.bi-receipt-cutoff::before {
  content: "\f50e"; }

.bi-receipt::before {
  content: "\f50f"; }

.bi-reception-0::before {
  content: "\f510"; }

.bi-reception-1::before {
  content: "\f511"; }

.bi-reception-2::before {
  content: "\f512"; }

.bi-reception-3::before {
  content: "\f513"; }

.bi-reception-4::before {
  content: "\f514"; }

.bi-record-btn-fill::before {
  content: "\f515"; }

.bi-record-btn::before {
  content: "\f516"; }

.bi-record-circle-fill::before {
  content: "\f517"; }

.bi-record-circle::before {
  content: "\f518"; }

.bi-record-fill::before {
  content: "\f519"; }

.bi-record::before {
  content: "\f51a"; }

.bi-record2-fill::before {
  content: "\f51b"; }

.bi-record2::before {
  content: "\f51c"; }

.bi-reply-all-fill::before {
  content: "\f51d"; }

.bi-reply-all::before {
  content: "\f51e"; }

.bi-reply-fill::before {
  content: "\f51f"; }

.bi-reply::before {
  content: "\f520"; }

.bi-rss-fill::before {
  content: "\f521"; }

.bi-rss::before {
  content: "\f522"; }

.bi-rulers::before {
  content: "\f523"; }

.bi-save-fill::before {
  content: "\f524"; }

.bi-save::before {
  content: "\f525"; }

.bi-save2-fill::before {
  content: "\f526"; }

.bi-save2::before {
  content: "\f527"; }

.bi-scissors::before {
  content: "\f528"; }

.bi-screwdriver::before {
  content: "\f529"; }

.bi-search::before {
  content: "\f52a"; }

.bi-segmented-nav::before {
  content: "\f52b"; }

.bi-server::before {
  content: "\f52c"; }

.bi-share-fill::before {
  content: "\f52d"; }

.bi-share::before {
  content: "\f52e"; }

.bi-shield-check::before {
  content: "\f52f"; }

.bi-shield-exclamation::before {
  content: "\f530"; }

.bi-shield-fill-check::before {
  content: "\f531"; }

.bi-shield-fill-exclamation::before {
  content: "\f532"; }

.bi-shield-fill-minus::before {
  content: "\f533"; }

.bi-shield-fill-plus::before {
  content: "\f534"; }

.bi-shield-fill-x::before {
  content: "\f535"; }

.bi-shield-fill::before {
  content: "\f536"; }

.bi-shield-lock-fill::before {
  content: "\f537"; }

.bi-shield-lock::before {
  content: "\f538"; }

.bi-shield-minus::before {
  content: "\f539"; }

.bi-shield-plus::before {
  content: "\f53a"; }

.bi-shield-shaded::before {
  content: "\f53b"; }

.bi-shield-slash-fill::before {
  content: "\f53c"; }

.bi-shield-slash::before {
  content: "\f53d"; }

.bi-shield-x::before {
  content: "\f53e"; }

.bi-shield::before {
  content: "\f53f"; }

.bi-shift-fill::before {
  content: "\f540"; }

.bi-shift::before {
  content: "\f541"; }

.bi-shop-window::before {
  content: "\f542"; }

.bi-shop::before {
  content: "\f543"; }

.bi-shuffle::before {
  content: "\f544"; }

.bi-signpost-2-fill::before {
  content: "\f545"; }

.bi-signpost-2::before {
  content: "\f546"; }

.bi-signpost-fill::before {
  content: "\f547"; }

.bi-signpost-split-fill::before {
  content: "\f548"; }

.bi-signpost-split::before {
  content: "\f549"; }

.bi-signpost::before {
  content: "\f54a"; }

.bi-sim-fill::before {
  content: "\f54b"; }

.bi-sim::before {
  content: "\f54c"; }

.bi-skip-backward-btn-fill::before {
  content: "\f54d"; }

.bi-skip-backward-btn::before {
  content: "\f54e"; }

.bi-skip-backward-circle-fill::before {
  content: "\f54f"; }

.bi-skip-backward-circle::before {
  content: "\f550"; }

.bi-skip-backward-fill::before {
  content: "\f551"; }

.bi-skip-backward::before {
  content: "\f552"; }

.bi-skip-end-btn-fill::before {
  content: "\f553"; }

.bi-skip-end-btn::before {
  content: "\f554"; }

.bi-skip-end-circle-fill::before {
  content: "\f555"; }

.bi-skip-end-circle::before {
  content: "\f556"; }

.bi-skip-end-fill::before {
  content: "\f557"; }

.bi-skip-end::before {
  content: "\f558"; }

.bi-skip-forward-btn-fill::before {
  content: "\f559"; }

.bi-skip-forward-btn::before {
  content: "\f55a"; }

.bi-skip-forward-circle-fill::before {
  content: "\f55b"; }

.bi-skip-forward-circle::before {
  content: "\f55c"; }

.bi-skip-forward-fill::before {
  content: "\f55d"; }

.bi-skip-forward::before {
  content: "\f55e"; }

.bi-skip-start-btn-fill::before {
  content: "\f55f"; }

.bi-skip-start-btn::before {
  content: "\f560"; }

.bi-skip-start-circle-fill::before {
  content: "\f561"; }

.bi-skip-start-circle::before {
  content: "\f562"; }

.bi-skip-start-fill::before {
  content: "\f563"; }

.bi-skip-start::before {
  content: "\f564"; }

.bi-slack::before {
  content: "\f565"; }

.bi-slash-circle-fill::before {
  content: "\f566"; }

.bi-slash-circle::before {
  content: "\f567"; }

.bi-slash-square-fill::before {
  content: "\f568"; }

.bi-slash-square::before {
  content: "\f569"; }

.bi-slash::before {
  content: "\f56a"; }

.bi-sliders::before {
  content: "\f56b"; }

.bi-smartwatch::before {
  content: "\f56c"; }

.bi-snow::before {
  content: "\f56d"; }

.bi-snow2::before {
  content: "\f56e"; }

.bi-snow3::before {
  content: "\f56f"; }

.bi-sort-alpha-down-alt::before {
  content: "\f570"; }

.bi-sort-alpha-down::before {
  content: "\f571"; }

.bi-sort-alpha-up-alt::before {
  content: "\f572"; }

.bi-sort-alpha-up::before {
  content: "\f573"; }

.bi-sort-down-alt::before {
  content: "\f574"; }

.bi-sort-down::before {
  content: "\f575"; }

.bi-sort-numeric-down-alt::before {
  content: "\f576"; }

.bi-sort-numeric-down::before {
  content: "\f577"; }

.bi-sort-numeric-up-alt::before {
  content: "\f578"; }

.bi-sort-numeric-up::before {
  content: "\f579"; }

.bi-sort-up-alt::before {
  content: "\f57a"; }

.bi-sort-up::before {
  content: "\f57b"; }

.bi-soundwave::before {
  content: "\f57c"; }

.bi-speaker-fill::before {
  content: "\f57d"; }

.bi-speaker::before {
  content: "\f57e"; }

.bi-speedometer::before {
  content: "\f57f"; }

.bi-speedometer2::before {
  content: "\f580"; }

.bi-spellcheck::before {
  content: "\f581"; }

.bi-square-fill::before {
  content: "\f582"; }

.bi-square-half::before {
  content: "\f583"; }

.bi-square::before {
  content: "\f584"; }

.bi-stack::before {
  content: "\f585"; }

.bi-star-fill::before {
  content: "\f586"; }

.bi-star-half::before {
  content: "\f587"; }

.bi-star::before {
  content: "\f588"; }

.bi-stars::before {
  content: "\f589"; }

.bi-stickies-fill::before {
  content: "\f58a"; }

.bi-stickies::before {
  content: "\f58b"; }

.bi-sticky-fill::before {
  content: "\f58c"; }

.bi-sticky::before {
  content: "\f58d"; }

.bi-stop-btn-fill::before {
  content: "\f58e"; }

.bi-stop-btn::before {
  content: "\f58f"; }

.bi-stop-circle-fill::before {
  content: "\f590"; }

.bi-stop-circle::before {
  content: "\f591"; }

.bi-stop-fill::before {
  content: "\f592"; }

.bi-stop::before {
  content: "\f593"; }

.bi-stoplights-fill::before {
  content: "\f594"; }

.bi-stoplights::before {
  content: "\f595"; }

.bi-stopwatch-fill::before {
  content: "\f596"; }

.bi-stopwatch::before {
  content: "\f597"; }

.bi-subtract::before {
  content: "\f598"; }

.bi-suit-club-fill::before {
  content: "\f599"; }

.bi-suit-club::before {
  content: "\f59a"; }

.bi-suit-diamond-fill::before {
  content: "\f59b"; }

.bi-suit-diamond::before {
  content: "\f59c"; }

.bi-suit-heart-fill::before {
  content: "\f59d"; }

.bi-suit-heart::before {
  content: "\f59e"; }

.bi-suit-spade-fill::before {
  content: "\f59f"; }

.bi-suit-spade::before {
  content: "\f5a0"; }

.bi-sun-fill::before {
  content: "\f5a1"; }

.bi-sun::before {
  content: "\f5a2"; }

.bi-sunglasses::before {
  content: "\f5a3"; }

.bi-sunrise-fill::before {
  content: "\f5a4"; }

.bi-sunrise::before {
  content: "\f5a5"; }

.bi-sunset-fill::before {
  content: "\f5a6"; }

.bi-sunset::before {
  content: "\f5a7"; }

.bi-symmetry-horizontal::before {
  content: "\f5a8"; }

.bi-symmetry-vertical::before {
  content: "\f5a9"; }

.bi-table::before {
  content: "\f5aa"; }

.bi-tablet-fill::before {
  content: "\f5ab"; }

.bi-tablet-landscape-fill::before {
  content: "\f5ac"; }

.bi-tablet-landscape::before {
  content: "\f5ad"; }

.bi-tablet::before {
  content: "\f5ae"; }

.bi-tag-fill::before {
  content: "\f5af"; }

.bi-tag::before {
  content: "\f5b0"; }

.bi-tags-fill::before {
  content: "\f5b1"; }

.bi-tags::before {
  content: "\f5b2"; }

.bi-telegram::before {
  content: "\f5b3"; }

.bi-telephone-fill::before {
  content: "\f5b4"; }

.bi-telephone-forward-fill::before {
  content: "\f5b5"; }

.bi-telephone-forward::before {
  content: "\f5b6"; }

.bi-telephone-inbound-fill::before {
  content: "\f5b7"; }

.bi-telephone-inbound::before {
  content: "\f5b8"; }

.bi-telephone-minus-fill::before {
  content: "\f5b9"; }

.bi-telephone-minus::before {
  content: "\f5ba"; }

.bi-telephone-outbound-fill::before {
  content: "\f5bb"; }

.bi-telephone-outbound::before {
  content: "\f5bc"; }

.bi-telephone-plus-fill::before {
  content: "\f5bd"; }

.bi-telephone-plus::before {
  content: "\f5be"; }

.bi-telephone-x-fill::before {
  content: "\f5bf"; }

.bi-telephone-x::before {
  content: "\f5c0"; }

.bi-telephone::before {
  content: "\f5c1"; }

.bi-terminal-fill::before {
  content: "\f5c2"; }

.bi-terminal::before {
  content: "\f5c3"; }

.bi-text-center::before {
  content: "\f5c4"; }

.bi-text-indent-left::before {
  content: "\f5c5"; }

.bi-text-indent-right::before {
  content: "\f5c6"; }

.bi-text-left::before {
  content: "\f5c7"; }

.bi-text-paragraph::before {
  content: "\f5c8"; }

.bi-text-right::before {
  content: "\f5c9"; }

.bi-textarea-resize::before {
  content: "\f5ca"; }

.bi-textarea-t::before {
  content: "\f5cb"; }

.bi-textarea::before {
  content: "\f5cc"; }

.bi-thermometer-half::before {
  content: "\f5cd"; }

.bi-thermometer-high::before {
  content: "\f5ce"; }

.bi-thermometer-low::before {
  content: "\f5cf"; }

.bi-thermometer-snow::before {
  content: "\f5d0"; }

.bi-thermometer-sun::before {
  content: "\f5d1"; }

.bi-thermometer::before {
  content: "\f5d2"; }

.bi-three-dots-vertical::before {
  content: "\f5d3"; }

.bi-three-dots::before {
  content: "\f5d4"; }

.bi-toggle-off::before {
  content: "\f5d5"; }

.bi-toggle-on::before {
  content: "\f5d6"; }

.bi-toggle2-off::before {
  content: "\f5d7"; }

.bi-toggle2-on::before {
  content: "\f5d8"; }

.bi-toggles::before {
  content: "\f5d9"; }

.bi-toggles2::before {
  content: "\f5da"; }

.bi-tools::before {
  content: "\f5db"; }

.bi-tornado::before {
  content: "\f5dc"; }

.bi-trash-fill::before {
  content: "\f5dd"; }

.bi-trash::before {
  content: "\f5de"; }

.bi-trash2-fill::before {
  content: "\f5df"; }

.bi-trash2::before {
  content: "\f5e0"; }

.bi-tree-fill::before {
  content: "\f5e1"; }

.bi-tree::before {
  content: "\f5e2"; }

.bi-triangle-fill::before {
  content: "\f5e3"; }

.bi-triangle-half::before {
  content: "\f5e4"; }

.bi-triangle::before {
  content: "\f5e5"; }

.bi-trophy-fill::before {
  content: "\f5e6"; }

.bi-trophy::before {
  content: "\f5e7"; }

.bi-tropical-storm::before {
  content: "\f5e8"; }

.bi-truck-flatbed::before {
  content: "\f5e9"; }

.bi-truck::before {
  content: "\f5ea"; }

.bi-tsunami::before {
  content: "\f5eb"; }

.bi-tv-fill::before {
  content: "\f5ec"; }

.bi-tv::before {
  content: "\f5ed"; }

.bi-twitch::before {
  content: "\f5ee"; }

.bi-twitter::before {
  content: "\f5ef"; }

.bi-type-bold::before {
  content: "\f5f0"; }

.bi-type-h1::before {
  content: "\f5f1"; }

.bi-type-h2::before {
  content: "\f5f2"; }

.bi-type-h3::before {
  content: "\f5f3"; }

.bi-type-italic::before {
  content: "\f5f4"; }

.bi-type-strikethrough::before {
  content: "\f5f5"; }

.bi-type-underline::before {
  content: "\f5f6"; }

.bi-type::before {
  content: "\f5f7"; }

.bi-ui-checks-grid::before {
  content: "\f5f8"; }

.bi-ui-checks::before {
  content: "\f5f9"; }

.bi-ui-radios-grid::before {
  content: "\f5fa"; }

.bi-ui-radios::before {
  content: "\f5fb"; }

.bi-umbrella-fill::before {
  content: "\f5fc"; }

.bi-umbrella::before {
  content: "\f5fd"; }

.bi-union::before {
  content: "\f5fe"; }

.bi-unlock-fill::before {
  content: "\f5ff"; }

.bi-unlock::before {
  content: "\f600"; }

.bi-upc-scan::before {
  content: "\f601"; }

.bi-upc::before {
  content: "\f602"; }

.bi-upload::before {
  content: "\f603"; }

.bi-vector-pen::before {
  content: "\f604"; }

.bi-view-list::before {
  content: "\f605"; }

.bi-view-stacked::before {
  content: "\f606"; }

.bi-vinyl-fill::before {
  content: "\f607"; }

.bi-vinyl::before {
  content: "\f608"; }

.bi-voicemail::before {
  content: "\f609"; }

.bi-volume-down-fill::before {
  content: "\f60a"; }

.bi-volume-down::before {
  content: "\f60b"; }

.bi-volume-mute-fill::before {
  content: "\f60c"; }

.bi-volume-mute::before {
  content: "\f60d"; }

.bi-volume-off-fill::before {
  content: "\f60e"; }

.bi-volume-off::before {
  content: "\f60f"; }

.bi-volume-up-fill::before {
  content: "\f610"; }

.bi-volume-up::before {
  content: "\f611"; }

.bi-vr::before {
  content: "\f612"; }

.bi-wallet-fill::before {
  content: "\f613"; }

.bi-wallet::before {
  content: "\f614"; }

.bi-wallet2::before {
  content: "\f615"; }

.bi-watch::before {
  content: "\f616"; }

.bi-water::before {
  content: "\f617"; }

.bi-whatsapp::before {
  content: "\f618"; }

.bi-wifi-1::before {
  content: "\f619"; }

.bi-wifi-2::before {
  content: "\f61a"; }

.bi-wifi-off::before {
  content: "\f61b"; }

.bi-wifi::before {
  content: "\f61c"; }

.bi-wind::before {
  content: "\f61d"; }

.bi-window-dock::before {
  content: "\f61e"; }

.bi-window-sidebar::before {
  content: "\f61f"; }

.bi-window::before {
  content: "\f620"; }

.bi-wrench::before {
  content: "\f621"; }

.bi-x-circle-fill::before {
  content: "\f622"; }

.bi-x-circle::before {
  content: "\f623"; }

.bi-x-diamond-fill::before {
  content: "\f624"; }

.bi-x-diamond::before {
  content: "\f625"; }

.bi-x-octagon-fill::before {
  content: "\f626"; }

.bi-x-octagon::before {
  content: "\f627"; }

.bi-x-square-fill::before {
  content: "\f628"; }

.bi-x-square::before {
  content: "\f629"; }

.bi-x::before {
  content: "\f62a"; }

.bi-youtube::before {
  content: "\f62b"; }

.bi-zoom-in::before {
  content: "\f62c"; }

.bi-zoom-out::before {
  content: "\f62d"; }

.bi-bank::before {
  content: "\f62e"; }

.bi-bank2::before {
  content: "\f62f"; }

.bi-bell-slash-fill::before {
  content: "\f630"; }

.bi-bell-slash::before {
  content: "\f631"; }

.bi-cash-coin::before {
  content: "\f632"; }

.bi-check-lg::before {
  content: "\f633"; }

.bi-coin::before {
  content: "\f634"; }

.bi-currency-bitcoin::before {
  content: "\f635"; }

.bi-currency-dollar::before {
  content: "\f636"; }

.bi-currency-euro::before {
  content: "\f637"; }

.bi-currency-exchange::before {
  content: "\f638"; }

.bi-currency-pound::before {
  content: "\f639"; }

.bi-currency-yen::before {
  content: "\f63a"; }

.bi-dash-lg::before {
  content: "\f63b"; }

.bi-exclamation-lg::before {
  content: "\f63c"; }

.bi-file-earmark-pdf-fill::before {
  content: "\f63d"; }

.bi-file-earmark-pdf::before {
  content: "\f63e"; }

.bi-file-pdf-fill::before {
  content: "\f63f"; }

.bi-file-pdf::before {
  content: "\f640"; }

.bi-gender-ambiguous::before {
  content: "\f641"; }

.bi-gender-female::before {
  content: "\f642"; }

.bi-gender-male::before {
  content: "\f643"; }

.bi-gender-trans::before {
  content: "\f644"; }

.bi-headset-vr::before {
  content: "\f645"; }

.bi-info-lg::before {
  content: "\f646"; }

.bi-mastodon::before {
  content: "\f647"; }

.bi-messenger::before {
  content: "\f648"; }

.bi-piggy-bank-fill::before {
  content: "\f649"; }

.bi-piggy-bank::before {
  content: "\f64a"; }

.bi-pin-map-fill::before {
  content: "\f64b"; }

.bi-pin-map::before {
  content: "\f64c"; }

.bi-plus-lg::before {
  content: "\f64d"; }

.bi-question-lg::before {
  content: "\f64e"; }

.bi-recycle::before {
  content: "\f64f"; }

.bi-reddit::before {
  content: "\f650"; }

.bi-safe-fill::before {
  content: "\f651"; }

.bi-safe2-fill::before {
  content: "\f652"; }

.bi-safe2::before {
  content: "\f653"; }

.bi-sd-card-fill::before {
  content: "\f654"; }

.bi-sd-card::before {
  content: "\f655"; }

.bi-skype::before {
  content: "\f656"; }

.bi-slash-lg::before {
  content: "\f657"; }

.bi-translate::before {
  content: "\f658"; }

.bi-x-lg::before {
  content: "\f659"; }

.bi-safe::before {
  content: "\f65a"; }
